import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/home/Index.vue'
import SearchView from '../views/home/Search.vue'
import DiscoverView from '../views/home/Discover.vue'
import ShopcarView from '../views/home/Shopcar.vue'
import MineView from '../views/home/Mine.vue'
import LoginView from '../views/home/Login.vue'

import YinsiView from '../views/home/Yinsi.vue'
import XieyiView from '../views/home/Xieyi.vue'

import ProductsView from '../views/home/Products.vue'
import UserInfoView from '../views/home/UserInfo.vue'
import SettingView from '../views/home/Setting.vue'
import FeedbackView from '../views/home/Feedback.vue'
import CancelAccountView from '../views/home/CancelAccount.vue'
import PhoneRechargeView from '../views/home/PhoneRecharge.vue'
import ShopDetailView from '../views/home/ShopDetail.vue'
import MyOrderView from '../views/home/MyOrder.vue'
import TestView from '../views/home/Test.vue'
import InviteListView from '../views/home/InviteList.vue'
import FanliView from '../views/home/Fanli.vue'
import CoinView from '../views/home/Coin.vue'
import ShareView from '../views/home/Share.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/search',
    name: 'search',
    component: SearchView
  },
  {
    path: '/discover',
    name: 'discover',
    component: DiscoverView
  },
  {
    path: '/shopcar',
    name: 'shopcar',
    component: ShopcarView
  },
  {
    path: '/mine',
    name: 'mine',
    component: MineView
  },
  {
    path: '/userInfo',
    name: 'userInfo',
    component: UserInfoView
  },
  {
    path: '/feedback',
    name: 'feedback',
    component: FeedbackView
  },
  {
    path: '/cancelAccount',
    name: 'cancelAccount',
    component: CancelAccountView
  },
  {
    path: '/setting',
    name: 'setting',
    component: SettingView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/yinsi',
    name: 'yinsi',
    component: YinsiView
  },
  {
    path: '/xieyi',
    name: 'xieyi',
    component: XieyiView
  },
  {
    path: '/products',
    name: 'products',
    component: ProductsView
  },
  {
    path: '/phoneRecharge',
    name: 'phoneRecharge',
    component: PhoneRechargeView
  },
  {
    path: '/shopDetail',
    name: 'shopDetail',
    component: ShopDetailView
  },
  {
    path: '/myOrder',
    name: 'myOrder',
    component: MyOrderView
  },
  {
    path: '/inviteList',
    name: 'inviteList',
    component: InviteListView
  },
  {
    path: '/fanli',
    name: 'fanli',
    component: FanliView
  },
  {
    path: '/coin',
    name: 'coin',
    component: CoinView
  },
  {
    path: '/share',
    name: 'share',
    component: ShareView
  },
  {
    path: '/test',
    name: 'test',
    component: TestView
  },

]

const router = createRouter({
  //history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
