<template>
<van-pull-refresh pulling-text="下拉即可刷新" v-model="loading" @refresh="onRefresh">
    <div style="background:#f7f7f7;">
    <!-- banner图区 -->
    <div style="width: 100%; margin-top: 3vw">
      <van-swipe :autoplay="3000" lazy-render>
        <van-swipe-item v-for="ban in bannerList" :key="ban" @click="toBannerActivity(ban.url)">
          <div class="card-div" :style="'background-image:url(' + ban.img + ');'">
            <!-- <img style="height:auto;width:100%" :src="image" /> -->
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>

    <!-- 金刚区 -->
    <div class="flex-box" style="flex-wrap: wrap;margin-top: 3vw;">
        <div v-for="jg in jingangList" :key="jg" class="flex-box-center" style="flex-direction: column;width: 20%;margin-bottom: 5px;" @click="toJingang(jg.url)">
            <div style="width:38px;height:38px;"><img style="width:100%;height:100%;" :src="jg.img" class="jingang-img"/></div>
            <div><span style="font-size:12px;color: #666;">{{jg.name}}</span></div>
        </div>
    </div>

    <div style="width:100%;margin-top:3vw" @click="jumpToActivity()">
        <img  :src="ad" style="width:100%;height:120px" />
    </div>

    <div class="flex-box" style="padding-left: 15px;margin-top:3vw">
        <div class="flex-box" style="width:100%;align-items: flex-end;">
            <span class="title import-font-color">猜你喜欢</span>
            <span class="support-font-color" style="font-size: 12px;margin-left: 8px;">/ 根据你的喜好推荐</span>
        </div>
        <!-- <div class="flex-box-center" style="height:40px;background-color:#FFB5B5">
            <img style="width:5vw;height:5vw;margin-right: 5px;" src="@/assets/image/new.png" />
            <span style="font-weight:600">全网最火优惠券</span>

        </div> -->
    </div>

    <!-- 搜索栏 -->
        <!-- <div class="flex-box" style="padding-left: 10px;margin-top:3vw;height: 35px;background-color: #FFFFFF">
            <div class="flex-box-center screen-font" style="width:20%;color:#FF3366"><span>综合</span></div>
            <div class="flex-box-center screen-font" style="width:20%"><span>最新</span></div>
            <div class="flex-box-center screen-font" style="width:20%"><span>销量</span></div>
            <div class="flex-box-center screen-font" style="width:20%"><span>到手价</span></div>
            <div class="flex-box-center screen-font" style="width:20%">
            <img style="height: 4.5vw; width: 4.5vw;" src="@/assets/image/search_dark.png" />
                <span>搜索</span>
            </div>
        </div>     -->
    <van-divider />
    <van-list v-model:loading="listLoading" :finished="finished" finished-text="没有更多了" @load="onLoad" style="padding-bottom: 25vw;" >
          <div class="flex-box" style="flex-wrap: wrap;margin-top: 1vw;" > 
        <div v-for="product in productList" :key="product.sku" class="flex-box-center" style="flex-direction: column;width: 50%;margin-top: 12px;" @click="jumpToDetail(product.product_id)">
            <div style="width:94%;height: 100%;padding-bottom: 10px;border-radius: 10px;background: #FFFFFF;overflow: hidden;">
                <div>
                  <!-- <img  :src="product.cover" class="shopping-img" /> -->
                  <van-image class="shopping-img" style="height"  :src="product.cover" >
                    <template v-slot:loading>
                      <van-loading type="spinner" size="20" />
                    </template>
                  </van-image>
                </div>
                <div style="margin-top:3px;padding-right: 5px;padding-left: 8px;" class="flex-box-center-col"><img class="jiaobiao-img" src="@/assets/image/dy.png" />
                <div class="product-div">{{product.title}}</div></div>
                <div style="margin-top:5px;padding-left: 8px" class="flex-box-center-col">
                    <!-- <div class="flex-box-center juan-card">卷43</div>  -->
                    <div class="flex-box-center vip-card-left">VIP</div><div class="flex-box-center vip-card-right">返利{{(product.cos_fee*0.01).toFixed(2)}}</div> 
                </div>
                <div style="margin-top:5px;padding-left: 8px" class="flex-box-center-col">
                    <div class="flex-box" style="width: 100%;align-items: center;"><span style="color:#F31A61;font-size:10px">到手价</span><span style="color:#F31A61;font-size:14px;font-weight: 600;">￥{{((product.price-product.cos_fee)*0.01).toFixed(2)}}</span></div> 
                    <!-- <div class="flex-box" style="justify-content: flex-end;width: 100%;margin-right: 5px;"><span style="font-size:10px;color:#C9C9C9">天猫月销84</span></div>   -->
                     <div class="flex-box" style="justify-content: flex-end;width: 100%;margin-right: 5px;"><del style="font-size:10px;color:#C9C9C9">原价￥{{(product.price*0.01).toFixed(2)}}</del></div>  
                </div>
                <!-- <div style="margin-top:5px;padding-left: 8px" class="flex-box-center-col"><del style="font-size:10px;color:#C9C9C9">原价￥{{product.price.toFixed(2)}}</del></div> -->
            </div>
        </div>
    </div>
    </van-list>

  </div>
</van-pull-refresh>

</template>
<style>
.product-div{
    color:#434343;
    font-size:14px;
    margin-left: 5px;
    width:90%;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.card-div {
  border-radius: 10px;
  height: 40vw;
  width: 96vw;
  background-size: 100% 100%;
  background-color: antiquewhite;
  margin-left: 2%;
  background-repeat: no-repeat;
}

.jingang-img{
    width:48px;
    height:48px
}

.shopping-img{
    width:100%;
    /* height:100% */
    height: 183px;
}

.jiaobiao-img{
    width:15px;
    height:15px
}


.juan-card{
    height: 15px;
    background-color: #FD1253;
    font-size: 10px;
    border-radius: 2px;
    padding-left: 4px;
    padding-right: 4px;
    color: #FFFFFF;
}

.vip-card-left{
    margin-left: 5px;
    height: 15px;
    background-color: #000000;
    font-size: 10px;
    border-top-left-radius:2px;
    border-bottom-left-radius:2px;
    padding-left: 4px;
    padding-right: 4px;
    color: #DCAEA0;
}

.vip-card-right{
    height: 15px;
    background: #B87249;
    font-size: 10px;
    border-top-right-radius:2px;
    border-bottom-right-radius:2px;
    padding-left: 4px;
    padding-right: 4px;
    color: #FFFFFF;
}
</style>
<script>
import { Swipe, SwipeItem } from "vant";
import { Sticky } from 'vant';
import { Tab, Tabs } from "vant"
import { Divider } from 'vant';
import { PullRefresh } from 'vant';
import { Toast } from 'vant';
import { List } from 'vant';
import { Lazyload } from 'vant';
import { getBanners,getIndexAd,getJingang,getIndexProductThirdPage ,getIProductLink} from '@/js/util/mgRequest.js'
import { Image as VanImage } from 'vant';
import { Loading } from 'vant';
export default {
  data() {
    return {
      loading:false,
      productList:[],
      bannerList:[],
      jingangList:[],
      ad:'',
      adUrl:'',
      listLoading:false,
      finished:false,
      currentPage:1,
      pageSize:20,
      loadImgSrc:'/image/good_default.png'
    };
  },
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Sticky.name]: Sticky,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Divider.name]: Divider,
    [PullRefresh.name]: PullRefresh,
    [Toast.name]: Toast,
    [List.name]: List,
    [Lazyload.name]: Lazyload,
    [VanImage.name]: VanImage,
    [Loading.name]: Loading
  },
  methods:{
      onRefresh(){
          var _this=this;
          _this.currentPage=1;
          var pageNum=_this.currentPage;
            setTimeout(() => {
              getIndexProductThirdPage('','',0,pageNum,20).then((res)=>{
                _this.productList=[];
                if(_this.currentPage>=res.pages){
                      _this.finished=true;
                  }
                  for(var index=0;index<res.data.length;index++){
                    _this.productList.push(res.data[index])
                }
                  _this.listLoading = false;
                  _this.loading=false;
                  Toast('又帮小主找到搜到了全网最低价');
              })
            }, 1000);



      },
      jumpToThird(channel,url){
        if(channel=="TMALL"){
            window.android.jumpToTmall(url)
          }else if(channel=="TAOBAO"){
            window.android.jumpToTaobao(url)
          }else if(channel=="JD"){
             window.android.jumpToJD(url)
          }else if(channel=="PDD"){
            window.android.jumpToPDD(url)
          }else if(channel=="MT"){
            window.android.jumpToMT(url)
          }
      },
      jumpToDetail(productId){
        window.android.jumpToCommonActivity("https://app.pxxpxxpxx.com/#/shopDetail?productId="+productId);
      },
      jumpDouyin(url){
        getIProductLink(url).then((res)=>{
            console.log(res)
            window.android.jumpToDy(res.data.dy_deeplink)
          })
      },
      toJingang(url){
        window.android.jumpToWebViewActivity(url);
      },
      jumpToActivity(){
        var url=this.adUrl
        window.android.jumpToWebViewActivity(url);
      },
      toBannerActivity(url){
        window.android.jumpToWebViewActivity(url);
      },
      onLoad(){
            var _this=this;
            var pageNum=_this.currentPage;

            getIndexProductThirdPage('','',0,pageNum,20).then((res)=>{
              if(_this.currentPage>=res.pages){
                    _this.finished=true;
                }
                for(var index=0;index<res.data.length;index++){
                    _this.productList.push(res.data[index])
                }
                _this.currentPage++;
                _this.listLoading = false;
            })
        },
  },
  computed:{
    convertImg:function(){
        return function(channel){
          if(channel=="TMALL"){
            return require('@/assets/image/tianmao.png')
          }else if(channel=="TAOBAO"){
            return require('@/assets/image/taobao.png')
          }else if(channel=="JD"){
            return require('@/assets/image/jingdong.png')
          }else if(channel=="PDD"){
            return require('@/assets/image/pdd.png')
          }else if(channel=="MT"){
            return require('@/assets/image/mt.png')
          }
        }
    }
  },
  mounted(){
      //获取banner图
      var _this=this;
      getBanners().then((res)=>{
          _this.bannerList=res.data
      })

      getIndexAd().then((res)=>{
        _this.ad=res.data[0].img
        _this.adUrl=res.data[0].url
      })

      getJingang().then((res)=>{
        _this.jingangList=res.data
      })

      // getIndexProduct().then((res)=>{
      //   _this.productList=res.data
      // })
  }
};
</script>
