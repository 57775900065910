<template>
  <van-list
    v-model:loading="loading"
    :finished="finished"
    finished-text="没有更多了"
    @load="onLoad"
  >
    <div v-for="item in list" :key="item">{{ item }}asdadadadaaada</div>
  </van-list>
</template>
<script>
import { ref } from "vue";
import { List } from "vant";
import { Cell } from "vant";
export default {
  data() {
    return {
      loading: false,
      finished: false,
      list:[]
    };
  },
  setup() {},
  components: {
    [List.name]: List,
    [Cell.name]: Cell,
  },
  methods: {
    onLoad() {
    var _this=this;
      setTimeout(() => {
        for (let i = 0; i < 10; i++) {
          _this.list.push(_this.list.length + 1);
        }

        // 加载状态结束
        _this.loading = false;

        // 数据全部加载完成
        if (_this.list.length >= 40) {
          _this.finished = true;
        }
      }, 1000);
    },
  },
};
</script>
