<template>
    <div style="width: 100%; margin-top: 3vw;background:#f7f7f7;">
    <div class="flex-box" style="flex-direction: column;">
        <div class="flex-box"> 
            <img style="width:100%" src="https://m.360buyimg.com/babel/jfs/t1/216853/24/18157/55439/62874382E4b961eb4/881a44f191d142a7.png.webp"/>
        </div>
        <div class="flex-box">
            <van-swipe :loop="false" :width="130" :show-indicators="false">
                <van-swipe-item  v-for="tuijianProduct in tuijianList" :key="tuijianProduct" @click="jumpToDetail(tuijianProduct.product_id)">
                    <div class="flex-box" style="width:110px;flex-direction: column;background-color: #FFFFFF;padding: 5px;margin-left:10px"> 
                    <img style="width:100%" :src="tuijianProduct.cover"/>
                    <div class="product-xianshi-div">{{tuijianProduct.title}}</div>
                    <div class="flex-box" style="width: 100%;align-items: center;"><span style="color:#F31A61;font-size:10px">到手价</span><span style="color:#F31A61;font-size:14px;font-weight: 600;">￥{{((tuijianProduct.price-tuijianProduct.cos_fee)*0.01).toFixed(2)}}</span></div> 
                </div>
                </van-swipe-item>
            </van-swipe>
        </div>
    </div>
    <div class="flex-box" style="padding-left: 15px;margin-top:3vw">
        <div class="flex-box" style="width:100%;align-items: flex-end;">
            <span class="title import-font-color">为你推荐</span>
            <span class="support-font-color" style="font-size: 12px;margin-left: 8px;">/ 精选品类好物</span>
        </div>
    </div>
    <van-list v-model:loading="listLoading" :finished="finished" finished-text="没有更多了" @load="onLoad" style="padding-bottom: 25vw;" > 
        <div class="flex-box" style="flex-wrap: wrap;margin-top: 1vw;padding-bottom: 25vw;"> 
        <div v-for="product in productList" :key="product" class="flex-box-center" style="flex-direction: column;width: 50%;margin-top: 12px;" @click="jumpToDetail(product.product_id)">
            <div style="width:94%;height: 100%;padding-bottom: 10px;border-radius: 10px;background: #FFFFFF;overflow: hidden;"  >
                <div><img :src="product.cover" class="shopping-img" /></div>
                <div style="margin-top:3px;padding-right: 5px;padding-left: 8px;" class="flex-box-center-col"><img class="jiaobiao-img" src="@/assets/image/dy.png" /><div class="product-div">{{product.title}}</div></div>
                <div style="margin-top:5px;padding-left: 8px" class="flex-box-center-col">
                    <!-- <div class="flex-box-center juan-card">卷43</div>  -->
                    <div class="flex-box-center vip-card-left">VIP</div><div class="flex-box-center vip-card-right">返利{{(product.cos_fee*0.01).toFixed(2)}}</div> 
                </div>
                <div style="margin-top:5px;padding-left: 8px" class="flex-box-center-col">
                    <div class="flex-box" style="width: 100%;align-items: center;"><span style="color:#F31A61;font-size:10px">到手价</span><span style="color:#F31A61;font-size:14px;font-weight: 600;">￥{{((product.price-product.cos_fee)*0.01).toFixed(2)}}</span></div> 
                    <!-- <div class="flex-box" style="justify-content: flex-end;width: 100%;margin-right: 5px;"><span style="font-size:10px;color:#C9C9C9">天猫月销84</span></div>   -->
                     <div class="flex-box" style="justify-content: flex-end;width: 100%;margin-right: 5px;"><del style="font-size:10px;color:#C9C9C9">原价￥{{(product.price*0.01).toFixed(2)}}</del></div>  
                </div>
                <!-- <div style="margin-top:5px;padding-left: 8px" class="flex-box-center-col"><del style="font-size:10px;color:#C9C9C9">原价￥{{product.price.toFixed(2)}}</del></div> -->
            </div>
        </div>
        <div style="width:100%"> 
            <van-divider>我也是有底线的</van-divider>
        </div>
        
    </div>
    </van-list>

</div>

</template>
<style scoped>
    .chaozhi-img{
        width:100px;
        height:110px
    }
    .product-xianshi-div{
        color: #434343;
        font-size: 12px;
        font-weight: 400;
        margin-left: 5px;
        width: 90%;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
</style>
<script>
import { getTypeProductPage,getIndexProductThirdPage ,getIProductLink} from '@/js/util/mgRequest.js'
import { Divider } from 'vant';
import { Swipe, SwipeItem } from "vant";
import { List } from 'vant';
export default({
    props: {
        id: String
    },
    data() {
        return {
            tuijianList:[],
            productList:[],
            listLoading:false,
            finished:false,
            currentPage:2,
            pageSize:20
        };
    },
    components: {
        [Divider.name]: Divider,
        [Swipe.name]: Swipe,
        [SwipeItem.name]: SwipeItem,
        [List.name]: List
    },
    mounted(){
      //获取banner图
      var _this=this;
      //这是上面推荐的
        getIndexProductThirdPage(_this.id,'',0,1,10).then((res)=>{
            for(var index=0;index<res.data.length;index++){
                _this.tuijianList.push(res.data[index])
            }
        })             

    //   getIndexProductPage(_this.id,'').then((res)=>{
    //       _this.productList=res;
    //   })
    },
    methods:{
        jumpToThird(channel,url){
            if(channel=="TMALL"){
                window.android.jumpToTmall(url)
            }else if(channel=="TAOBAO"){
                window.android.jumpToTaobao(url)
            }else if(channel=="JD"){
                window.android.jumpToJD(url)
            }else if(channel=="PDD"){
                window.android.jumpToPDD(url)
            }else if(channel=="MT"){
                window.android.jumpToMT(url)
            }
        },
        jumpToThird2(channel,url){
            if(channel=="TMALL"){
                window.android.jumpToTmall(url)
            }else if(channel=="TAOBAO"){
                window.android.jumpToTaobao(url)
            }else if(channel=="JD"){
                window.android.jumpToJD(url)
            }else if(channel=="PDD"){
                window.android.jumpToPDD(url)
            }else if(channel=="MT"){
                window.android.jumpToMT(url)
            }
        },
        jumpToDetail(productId){
            window.android.jumpToCommonActivity("https://app.pxxpxxpxx.com/#/shopDetail?productId="+productId);
        },
        jumpDouyin(url){
            getIProductLink(url).then((res)=>{
                console.log(res)
                window.android.jumpToDy(res.data.dy_deeplink)
            })
        },
        onLoad(){
            var _this=this;
            var pageNum=_this.currentPage;
            getIndexProductThirdPage(_this.id,'',0,pageNum,20).then((res)=>{
              if(_this.currentPage>=res.pages){
                    _this.finished=true;
                }
                for(var index=0;index<res.data.length;index++){
                    _this.productList.push(res.data[index])
                }
                _this.currentPage++;
                _this.listLoading = false;
            })
        }
    }
})
</script>
