<template>
    <div class="my-order">
        <div class="header">
            <div class="nav flex-box-center">
                <div style="flex:1.5" @click="onClickLeft()"><van-icon name="arrow-left" /></div>
                <div style="flex:5">
                    <span>{{orderText}}</span>
                </div>
                <div style="flex:1.5"><span style="font-size:12px">常见问题</span></div>
            </div>
        </div>
        <div> 
            <van-notice-bar left-icon="question-o" text="订单同步可能会有5-10分钟的延迟 " />
        </div>
        <div class="order-div flex-box"> 
            <van-list v-model:loading="listLoading" :finished="finished" finished-text="没有更多了" @load="onLoad" style="padding-bottom: 25vw;" > 
                <div class="order-card" v-for="order in orderList" :key="order.id">
                    <div style="padding:10px"> 
                        <div class="order-title flex-box-center-col flex-row">
                            <div  class="qudao-img"><img style="height:100%;width:100%" src="@/assets/image/dy.png"/></div>
                            <div class="shop-name"><span>{{order.shopName}}</span></div>
                            <div class="order-status">{{convertStatus(order.rebateStatus)}}</div>
                        </div>
                        <div class="order-content flex-box"> 
                            <div class="order-img flex-box">
                                <img style="width:100%;height:100%" :src="order.productImg"/>
                            </div>
                            <div class="flex-box" style="flex:1">
                                <div> 
                                    <span class="good-name">{{order.productName}}</span>
                                </div>
                            </div>
                            <div class="flex-box-center-row order-price">
                                <span>￥{{(order.productPrice*0.01).toFixed(2)}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="order-jl flex-box-center-col" style="padding-left:10px;padding-right:10px">
                        <div class="flex-box-center-col" style="flex:1"> 
                            <img style="height:30px;width:30px" src="@/assets/image/jianglihongbao.png" />
                            <span style="font-size:12px;letter-spacing: 1px;">可获返利红包<span style="color:red;font-size:14px;margin-left: 2px;margin-right: 2px;font-weight: 600;">{{(order.rebateAmount*0.01).toFixed(2)}}</span>元</span>
                        </div> 
                        <div class="flex-box"> 
                            <button class="btn-order-detail">订单详情</button>
                        </div>

                        
                    </div>
                </div>
            </van-list>
        </div>
    </div>
</template>
<style scoped>
    .my-order{
        min-height: 100vh;
        background-color: #F3F3F3;
    }

    .header{
        background-color: #ed5149;
        height: 60px;
    }

    .nav{
        padding-top: 2vh;
        color: #FFF;
    }

    .nav div{
        display: flex;
        justify-content: center;
    }

    .order-div{
        padding: 10px;
    }

    .order-card{
        background-color: #FFFFFF;
        width: 100%;
        border-radius: 10px;
        overflow: hidden;
        margin-top: 10px;
    }

    .qudao-img{
        width:14px;
        height:14px
    }

    .shop-name{
        font-size:14px;
        color: #434343;
        margin-left: 5px;
        flex:1;
        text-overflow:ellipsis;
        font-weight: 600;
    }

    .shop-name span{
        width:140px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;display: block;
    }

    .order-status{
        font-size:12px;
        color:#8B8D8C;
        margin-left: 5px;
    }

    .order-img{
        width:100px;
        height:100px;
        border-radius: 5px;
        overflow: hidden;
    }

    .good-name{
        color: #434343;
        font-size: 12px;
        font-weight: 400;
        margin-left: 10px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        word-break: break-all;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .order-price{
        width:50px;
        font-size:12px;
        color: #434343;

        font-weight: 600;
    }

    .order-content{
        margin-top: 8px;
    }

    .order-jl{
        background-color: #fef6f4;
        height: 40px;
    }

    .btn-order-detail{
        border: 1px solid;
        border-radius: 15px;
        height: 28px;
        width: 80px;
        font-size: 12px;
        color:#FFF;
        background-color: #ef8f36;
    }


</style>
<script>
import { Icon } from 'vant';
import { DropdownMenu, DropdownItem } from 'vant';
import { NoticeBar } from 'vant';
import { getOrders } from '@/js/util/mgRequest.js'
import { List } from 'vant';
export default({
    data() {
        return {
            value1:0,
            option1:[
                { text: '我的订单', value: 0 },
            ],
            orderList:[],
            listLoading:false,
            finished:false,
            currentPage:1,
            pageSize:20,
            orderText:'我的订单'
        };
    },
    components: {
        [Icon.name]: Icon,
        [DropdownMenu.name]: DropdownMenu,
        [DropdownItem.name]: DropdownItem,
        [NoticeBar.name]: NoticeBar,
        [List.name]: List,
    },
    methods:{
        onLoad(){
            var status = this.$route.query.status;
            if(status=='0'){
                status=''
            }
            var _this=this;
            var pageNum=_this.currentPage;

            getOrders(status,1,20).then((res)=>{
              if(_this.currentPage>=res.pages){
                    _this.finished=true;
                }
                for(var index=0;index<res.data.length;index++){
                    _this.orderList.push(res.data[index])
                }
                _this.currentPage++;
                _this.listLoading = false;
            })
        },
        onClickLeft(){
            window.android.finishThisPage();
        },
    },
    mounted(){
        var status = this.$route.query.status;
        if(status=="0"){
            this.orderText='全部订单'
        }else if(status=="1"){
            this.orderText='返利中的订单'
        }else if(status=="2"){
            this.orderText='已到账订单'
        }else if(status=="3"){
            this.orderText='失败的订单'
        }
    },
    computed:{
        convertStatus:function(){
            return function(status){
                if(status=="0"){
                    return '追踪中'
                }else if(status=="1"){
                    return '返利中'
                }else if(status=="2"){
                    return '已到账'
                }else if(status=="3"){
                    return '返利失败'
                }
            }
        }
  },
})
</script>