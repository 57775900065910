// 封装请求
import axios from 'axios'
import { Toast } from 'vant'; 
// 请求
const http = axios.create({
    baseURL: "https://app.pxxpxxpxx.com",
    //baseURL: "http://127.0.0.1:8085",
    //baseURL: "http://192.168.31.137:8085",
    //baseURL: "./mogeapi",
    timeout: 10000
})
// 请求拦截
http.interceptors.request.use(config => {
    //请求头设置
    let token = localStorage.getItem('token') || ''
    config.headers["x-token"] = token
    return config
}, err => {
    console.log(err);
})
// 响应拦截
http.interceptors.response.use(resp => {
    // 对响应码的处理
    if(resp.data.code===0){
        return resp.data
    }else{
        if(!resp.config.url=='/mogeapi/login/getUserInfo'){
            Toast(resp.data.message);
        }
        return resp.data
    }
}, err => {
    console.log(err);
})
// 返出
export default http