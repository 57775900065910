<template>
    <div>
    <!-- <div style="position:fixed;top:0;z-index: 999; height:10vw;width: 100%; left:0;background-color: #F2F2F2"></div> -->
    <div style="position:fixed;top:0;z-index: 999;height: 25vw;width: 100%; background:#F2F2F2;"> 
      <div class="flex-box-center" style="margin-top: 10vw;padding-left: 20px;padding-right: 20px;">
        <div class="flex-box-center-row" @click="close()">  
            <svg style="height:20px;width:20px" class="demo-nav__back" viewBox="0 0 1000 1000"><path fill="#969799" fill-rule="evenodd" d="M296.114 508.035c-3.22-13.597.473-28.499 11.079-39.105l333.912-333.912c16.271-16.272 42.653-16.272 58.925 0s16.272 42.654 0 58.926L395.504 498.47l304.574 304.574c16.272 16.272 16.272 42.654 0 58.926s-42.654 16.272-58.926 0L307.241 528.058a41.472 41.472 0 0 1-11.127-20.023z"></path></svg>
        </div>
        <div class="flex-box-center-col"> 
            <!-- <van-icon name="search" /> -->
            <!-- <input v-model="keyWords" type="search" @keypress="searchBtn()" placeholder="请输入搜索关键词" style="border:0;background: 0 0; font-size:12px;margin-left: 5px;height: 20px;" /> -->
            <form action="/">
            <van-search ref="searchinput" v-model="keyWords" @search="onSearch()" shape="round" background="#F2F2F2" placeholder="请输入搜索关键词" />      
            </form>   
           </div>
        <div class="flex-box" style="margin-left:5px">  
            <button @click="search()" class="search-button">搜索</button>
        </div>
        
      </div>
    </div>
    <div v-show="showTj" class="flex-box" style="margin-top:25vw;flex-direction: column;padding:10px;"> 
        <div class="flex-box"> 
          <span style="font-size:12px;color:#929292">热门搜索</span>
      </div>
      <div class="flex-box" style="margin-top:10px;flex-wrap: wrap;"> 
          <div @click="search('美食')" class="flex-box-center search-keyword-div"><span>美食</span></div>
          <div @click="search('女装')" class="flex-box-center search-keyword-div"><span>女装</span></div>
          <div @click="search('笔记本')" class="flex-box-center search-keyword-div"><span>笔记本</span></div>
          <div @click="search('女装品牌')" class="flex-box-center search-keyword-div"><span>女装品牌</span></div>
          <div @click="search('数码相机')" class="flex-box-center search-keyword-div"><span>数码相机</span></div>
          <div @click="search('母婴用品')" class="flex-box-center search-keyword-div"><span>母婴用品</span></div>
          <div @click="search('床上用品')" class="flex-box-center search-keyword-div"><span>床上用品</span></div>
      </div>
      <div class="flex-box" style="margin-top:20px"> 
          <img style="width:100%;height:22vw" @click="jumpToActivity()" src="https://img12.360buyimg.com/img/s750x205_jfs/t1/27179/26/16747/40010/629db300Eb380706e/918f227a1b5a178a.png.webp"/>
      </div>
    </div>
    <!-- 商品搜索列表 -->
    <div class="flex-box" style="        padding-bottom: 80px;">
      <van-list v-model:loading="listLoading" :finished="finished" finished-text="没有更多了" @load="onLoad" style="" :immediate-check="false"> 
            <div class="flex-box ba" style="flex-direction: column;align-items: center;margin-top:25vw;">
              <div  v-for="product in productList" :key="product" class="flex-box rank-card-div" @click="jumpToDetail(product.product_id)">
                  <div class="flex-box">
                      <img :src="product.cover" style="width:150px;padding-left:10px;padding-top:5px;padding-bottom:5px" />
                  </div>
                  <div class="flex-box" style="flex-direction: column;width: 100%;padding: 10px;"> 
                      <div class="flex-box" style="height:30%;width: 100%;align-items: center;">
                          <img class="jiaobiao-img" src="@/assets/image/dy.png" /><div class="product-div" style="margin-left:5px">{{product.title}}</div>
                      </div>
                      <div class="flex-box" style="height:30%;width: 100%;align-items: center;">
                           <div class="flex-box-center vip-card-left">VIP</div> <div class="flex-box-center vip-card-right">返利{{(product.cos_fee*0.01).toFixed(2)}}</div> 
                      </div>
                      <div class="flex-box" style="height:30%;width: 100%;align-items: center;">
                          <div class="flex-box" style="width: 100%;align-items: center;"><span style="color:#F31A61;font-size:10px">到手价</span><span style="color:#F31A61;font-size:14px;font-weight: 600;">￥{{((product.price-product.cos_fee)*0.01).toFixed(2)}}</span></div> 
                      </div>
                      <div class="flex-box-center" style="height:8vw;width: 100%">
                          <div style="width:100%;height:100%" v-bind:style="{backgroundImage:'url(' + bg + ')',
                                  backgroundRepeat:'no-repeat',
                                  backgroundSize:'100% 100%'}">
                                  <div class="flex-box-center" style="width:100%;height:100%">
                                      <div class="flex-box-center" style="width:100%"><span style="color:#e02e24;font-size:13px">直降{{(product.cos_fee*0.01).toFixed(2)}}元</span> </div>
                                      <div class="flex-box-center" style="width:100%"><span style="color:#FFFFFF;font-size: 13px;">立即购买></span> </div>
                                  </div>
                          </div>
                      </div>
                  </div>
              </div>
      </div>
      </van-list>

    </div>
    
  </div>
</template>
<style scoped>
    .searche-box{
          background: #FFFFFF;
          height: 30px;
          border-radius: 15px;
          padding-left: 20px;
          width: 100%;
          margin-left: 5px;
          font-size: 14px;

    }

    .search-button{
      background: #E42E21;
      border-radius: 15px;
      color: #FFFFFF;
      border:0;
      font-size: 12px;
      width: 60px;
      height: 30px;
    }


.product-div{
    color:#434343;
    font-size:14px;
    margin-left: 5px;
    width:90%;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-weight: 600;
}
    .ba{
        background-color: #F2F2F2;

    }

    .rank-card-div{
        margin-top: 8px;
        border-radius: 10px;
        height: 150px;
        width: 96%;
        background-color: #FFFFFF;
    }

    .search-keyword-div{
      background: #F2F2F2;
      border-radius: 15px;
      padding: 5px;
      min-width: 40px;
      margin-left: 10px;
      margin-top: 5px;
    }

    .search-keyword-div span{
      font-size:12px;
      font-weight: 400;
    }
    
</style>
<script>
import "@/assets/css/home/home.css"
import IndexComponent from '@/components/home/IndexComponent.vue'
import ShoppingComponent from '@/components/home/ShoppingComponent.vue'
import { Tab, Tabs } from "vant"
import { Search } from 'vant';
import { Icon } from 'vant';
import { searchProduct } from '@/js/util/mgRequest.js'
import { Divider } from 'vant';
import { useWindowSize } from "@vant/use";
import { List } from 'vant';

export default {
  data() {
    return {
      showTj:true,
      bg: require('@/assets/image/temai.png'),
      productList:[],
      keyWords:'',
      currentPage:1,
      pageSize:20,
      finished:false,
      listLoading:false,
    };
  },
  components: {
    IndexComponent,
    ShoppingComponent,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Search.name]: Search,
    [Icon.name]: Icon,
    [Divider.name]: Divider,
    [List.name]: List,
  },
  methods:{
    search(selectKeyWord){
      var _this=this;
      var key="";
      if(selectKeyWord!=null){
        key=selectKeyWord;
      }else{
        key=_this.keyWords.trim();
      }

      this.productList=[];
      this.currentPage=1;
      var pageNum=this.currentPage
      searchProduct(key,pageNum,10).then((res)=>{
          if(res.total===0){
            this.loadAllData();
          }else{
            for(var index=0;index<res.data.length;index++){
                _this.productList.push(res.data[index])
            }
            _this.currentPage++;
            _this.showTj=false;
          }

      })
    },
    jumpToDetail(productId){
        window.android.jumpToCommonActivity("https://app.pxxpxxpxx.com/#/shopDetail?productId="+productId);
    },
    jumpToActivity(){
     window.android.jumpToWebViewActivity('https://union-click.jd.com/jdc?e=618%7Cpc%7C&p=JF8BAMgJK1olXDYDZBoCUBVIMzZNXhpXVhgcEh4fFxBCHD1WR0VUA0VHBANDTgIJQTtaQQNIAGheSgYCXR9eHC1RXgdIU0RHWF5tC0h0RQpjcwd3Ak4GBgoOaCtWUXVKXVcZbQYHUl1dCXsXAm4IElsRWwIHZG5dC3tcbW44GFoQXAELUl9cAXsXBGsMG1MRWgYDVFhcOEwXCl9TTjUTVFFSUFpeW0JCBzxfK2slXjYFVFdJDjlWUXsOaWslXDYBZAEZVRYnAW4JH1IlbQ')
    },
    close(){
        window.android.finishThisPage();
    },
    onLoad(){
      var _this=this;
      var key=this.keyWords;
      var pageNum=this.currentPage
      searchProduct(key,pageNum,10).then((res)=>{
        if(_this.currentPage>=res.pages){
          _this.finished=true;
        }
        for(var index=0;index<res.data.length;index++){
          _this.productList.push(res.data[index])
        }
        _this.listLoading = false;
        _this.currentPage++;
        _this.showTj=false;
      })
    },
    loadAllData(){
      var _this=this;
      this.keyWords='';
      this.productList=[];
      this.currentPage=1;
      var pageNum=this.currentPage
      var key=this.keyWords;
      searchProduct(key,pageNum,10).then((res)=>{
        if(_this.currentPage>=res.pages){
          _this.finished=true;
        }
        for(var index=0;index<res.data.length;index++){
          _this.productList.push(res.data[index])
        }
        _this.listLoading = false;
        _this.currentPage++;
        _this.showTj=false;
      })

    },
    onSearch(){
       var key=this.keyWords.trim()
       this.search(key)
    }
  },
  mounted(){
    this.$refs.searchinput.focus();
  },
}
</script>