<template>
    <div class="share"> 
        <div style="width:100%;height:220px">
            <img style="width:100%;height:100%" src="@/assets/image/invite_1.png"/>
        </div>
        <div class="flex-box-center" style="margin-top:30px"> 
            <span style="font-weight:600">分享邀请码赚钱：</span><span style="font-weight:600;color: red;">{{inviteCode}}</span>
        </div>
        <div class="flex-box-center" style="margin-top:30px">
            <img style="width:200px;height:80px" src="@/assets/image/ljyq_icon.gif"/>
        </div>
        <div class="flex-box-center" style="margin-top:30px">
            <span style="margin-right:10px">限时福利</span>
            <van-count-down format="mm:ss:S" millisecond :time="time">
                <template #default="timeData">
                    <span class="block">{{ timeData.minutes }}</span>
                    <span class="colon">:</span>
                    <span class="block">{{ timeData.seconds}}</span>
                    <span class="colon">:</span>
                    <span class="block">{{ (timeData.milliseconds/10).toFixed(0) }}</span>
                </template>
            </van-count-down>
        </div>
        <div class="flex-box-center" style="margin-top:30px">
            <div class="flex-box-center" style="flex:1">
                <img style="width:80%;height:80%" src="@/assets/image/invite_4.png" />
            </div>
            <div class="flex-box-center" style="flex:1">
                <img style="width:80%;height:80%" src="@/assets/image/invite_2.png" />
            </div>
            <div class="flex-box-center" style="flex:1">
                <img style="width:80%;height:80%" src="@/assets/image/invite_3.png" />
            </div>
        </div>
        <div class="van-hairline--top"></div>
        <div class="flex-box-center" style="margin-top:60px">
            <div class="flex-box-center" style="flex:1;flex-direction: column;" @click="shareContent('wx')">
                <div class="van-share-sheet__icon van-share-sheet__icon--wechat"><i class="van-badge__wrapper van-icon van-icon-wechat"><!----><!----><!----></i></div>
                <span style="font-size:14px;margin-top: 8px;">微信</span>
            </div>
            <div class="flex-box-center" style="flex:1;flex-direction: column;" @click="shareContent('pyq')">
                <div class="van-share-sheet__icon van-share-sheet__icon--wechat-moments"><i class="van-badge__wrapper van-icon van-icon-wechat-moments"><!----><!----><!----></i></div>
                <span style="font-size:14px;margin-top: 8px;">朋友圈</span>
            </div>
            <div class="flex-box-center" style="flex:1;flex-direction: column;" @click="shareContent('link')">
                    <svg t="1662384313786" style="height:48px;width:48px" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2201" width="32" height="32"><path d="M512 1024C229.228 1024 0 794.772 0 512S229.228 0 512 0s512 229.228 512 512-229.228 512-512 512z m54.545-565.02l-1.798-1.764a102.207 102.207 0 0 0-11.002-9.523l-35.453 35.442c4.176 2.446 8.078 5.393 11.605 8.92l1.866 1.763a58.277 58.277 0 0 1 0 82.341l-96.904 96.882a58.334 58.334 0 0 1-82.341 0l-1.832-1.798a58.243 58.243 0 0 1 0-82.306l43.816-43.828a149.675 149.675 0 0 1-10.866-58.732l-67.812 67.72c-41.836 41.825-41.836 110.251 0 152.053l1.787 1.798c41.836 41.79 110.228 41.79 152.052 0l96.882-96.916c41.757-41.825 41.757-110.25 0-152.052z m141.38-141.37l-1.82-1.797c-41.802-41.825-110.228-41.825-152.053 0l-96.882 96.916c-41.824 41.79-41.824 110.216 0 152.053l1.798 1.763c3.505 3.425 7.225 6.576 11.002 9.523l35.454-35.476a57.845 57.845 0 0 1-11.583-8.92l-1.798-1.763a58.311 58.311 0 0 1 0-82.375l96.905-96.882a58.197 58.197 0 0 1 82.284 0l1.798 1.797a58.277 58.277 0 0 1 0 82.341l-43.76 43.828c7.612 18.796 11.196 38.81 10.844 58.766l67.789-67.755c41.824-41.79 41.824-110.216 0.022-152.007z" p-id="2202" fill="#8a8a8a"></path></svg>            
                    <span style="font-size:14px;margin-top: 8px;">分享链接</span>
                </div>
                    
            </div>
        </div>
</template>
<style scoped>
    .share{
        min-height: 100vh;
        background-color: #F3F3F3;
    }

  .colon {
    display: inline-block;
    margin: 0 4px;
    color: #ee0a24;
  }
  .block {
    display: inline-block;
    width: 22px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    background-color: #ee0a24;
    border-radius: 3px;
  }
</style>
<script>
import { CountDown } from 'vant';
import { getMyInviteCode} from '@/js/util/mgRequest.js'
import { Toast } from 'vant';
export default({
        data() {
            return {
                time:30 * 60 * 60 * 1000,
                inviteCode:''

            };
        },
        components: {
            [CountDown.name]: CountDown,
            [Toast.name]: Toast,
        },
        methods:{
            onClickLeft(){
                window.android.finishThisPage();
            },
            shareContent(id){
                var inviteCode=this.inviteCode;
                var shareUrl="快来省钱365，跟我一起获取金币吧，点击链接： http://commonnew.pxxpxxpxx.com?inviteCode="+inviteCode;
                var url='http://commonnew.pxxpxxpxx.com?inviteCode='+inviteCode
                var data={
                    tittle:'',
                    des:'快来省钱365，跟我一起获取金币吧，点击链接',
                    url:url,
                    img:''
                }
                if(id=="wx"){
                    window.android.shareWx(JSON.stringify(data))
                }else if(id=='pyq'){
                    window.android.sharePYQ(JSON.stringify(data))
                }else if(id=='link'){
                    const ipt=document.createElement('input')
                    document.body.appendChild(ipt)
                    ipt.setAttribute('value',shareUrl)
                    ipt.select()
                    document.execCommand('copy')
                    document.body.removeChild(ipt)
                    Toast("分享链接已复制到剪切板，快分享给好友吧~")
                }
            }
        },
        mounted (){
            var _this=this;
            getMyInviteCode().then((res)=>{
                _this.inviteCode=res.data.inviteCode
            })
        }
})
</script>