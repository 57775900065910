<template>
    <div class="my-userinfo"> 
        <van-nav-bar title="个人信息" left-text="" left-arrow @click-left="onClickLeft" />
        <div>
            <van-cell-group>
                <!-- <van-cell title="头像"  /> -->
                <van-cell title="昵称" :value="userInfo.nickName"/>
                <van-cell title="手机号" :value="userInfo.phone"/>
                <van-cell title="用户编号" :value="userInfo.custCode"/>
            </van-cell-group>
        </div>
    </div>

    <!-- <div class="flex-box-center" style="margin-top:40px"> 
        <van-button round  block style="width:90%" color="linear-gradient(to right, #ff6034, #ee0a24)">
        退出登录
        </van-button>
    </div> -->
</template>
<style scoped>
    .my-userinfo{
        
    }
</style>
<script>
import { Cell, CellGroup } from 'vant';
import { Button } from 'vant';
import { NavBar } from 'vant';
export default({
    
    components: {
        [Cell.name]: Cell,
        [CellGroup.name]: CellGroup,
        [Button.name]: Button,
        [NavBar.name]: NavBar,
    },
   data(){
        return{
            userInfo:{}
        }
    },
    mounted () {
        const userInfo=localStorage.getItem("userInfo");
        if(userInfo==null||userInfo==""){
            return;
        }

        this.userInfo=JSON.parse(userInfo)
    },
    methods:{
        onClickLeft(){
            window.android.finishThisPage();
        }
    }

})
</script>
