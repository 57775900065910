<template>
    <div>
        <van-nav-bar title="我要提现" left-text="" left-arrow @click-left="onClickLeft()" />
    </div>
    <div class="flex-box-center-col banner-div flex-col" >
        <span style="font-size:14px;margin-top: 80px;">可提现金额(元)</span>
        <span style="font-size:26px;margin-top: 5px;color:#FFD266;"> {{(rebateBalance*0.01).toFixed(2)}}</span>
    </div>
    <div class="flex-box-center" style="margin-top:-100px">
        <div class="tixian">
            <div class="coin-item" style="margin-top:20px">
                <div style="margin-left:5%"><span style="font-size:14px;font-weight:600">普通提现</span></div>
                <div class="flex-box" style="flex-wrap:wrap;margin-top:10px" > 
                    <div class="flex-box-center jine-div" :class="{'jine-div-checked':jineActivity==5}" @click="chooseAmount(5)">
                        <span>5元</span>
                    </div>
                    <div class="flex-box-center jine-div" :class="{'jine-div-checked':jineActivity==10}" @click="chooseAmount(10)">
                        <span>10元</span>
                    </div>
                    <div class="flex-box-center jine-div" :class="{'jine-div-checked':jineActivity==30}" @click="chooseAmount(30)">
                        <span>30元</span>
                    </div>
                    <div class="flex-box-center jine-div" :class="{'jine-div-checked':jineActivity==50}" @click="chooseAmount(50)">
                        <span>50元</span>
                    </div>
                    <div class="flex-box-center jine-div" :class="{'jine-div-checked':jineActivity==80}" @click="chooseAmount(80)">
                        <span>80元</span>
                    </div>
                    <div class="flex-box-center jine-div" :class="{'jine-div-checked':jineActivity==100}" @click="chooseAmount(100)">
                        <span>100元</span>
                    </div>
                </div>

            </div>

            <div class="flex-box-center" style="margin-top:20px"> 
                <div @click="fanliBack()"><img style="width:100%;height:40px" src="@/assets/image/lijitixian.png" /> </div>
            </div>

        </div>
    </div>
    <div class="flex-box-center"> 
        <div class="flex-box flex-col" style="width:90%;margin-top: 20px"> 
            <span class="title">温馨提示：</span>
            <span class="content">1.活动提现中新人专享的提现金额每人仅限1次提现机会</span>
            <span class="content">2.普通提现中的提现金额每人不限提现机会</span>
            <span class="content">3.通常情况下提交提现后24小时内到账，如遇提现高峰期，可能延迟到账，请主人耐心等待哦</span>
            <span class="content">4.一位用户每天仅有1次提现机会哦，请明确您要提现的金额</span>
            <span class="content">5.提现额度以页面显示金额选项为准，如果您的账户金额未达到提现额度，将不支持提现</span>
            <span class="content">到账查询方式：</span>
            <span class="content">省钱365提现查询;可提现红包>提现页面>提现页面</span>
        </div>

    </div>
</template>
<style scoped>
     .banner-div{
        background :url('@/assets/image/redbg.png') no-repeat;
        background-size: 100%;
        height: 280px;
        color: #FFFFFF;
     }

     .tixian{
        border-radius: 15px;
        width: 90%;
        height: 320px;
        background: #FFFFFF;
        /* background: url("@/assets/image/lingxianjin.png") no-repeat; */
        background-size: 100%;
        box-shadow: rgb(104 102 102 / 30%) 0 0 10px;
    }


     .jine-div{
        background: url('@/assets/image/jine-default.png');
        background-size: 100%;
        height: 60px;
        width: 100px;
        margin-left: 12px;
        margin-top: 10px;
     }

     .jine-div-checked{
        background: url('@/assets/image/jine-checked.png');
        background-size: 100%;
     }

     .btn-coin{
        background: #3AAF2B;
        border: none;
        border-radius: 30px;
        height:45px;
        width:80%;
        color:#FFFFFF
     }

     .title{
        font-size:14px;
        font-weight: 600;
        letter-spacing: 1px;
     }

     .content{
        margin-top: 3px;
        font-size:14px;
        color:#8B8D8C
     }
</style>
<script>
    import { Toast } from 'vant';
import { NavBar ,} from 'vant';
import { getMyAccount,withdrawRebate } from '@/js/util/mgRequest.js'
export default({
    data() {
        return {
            loading:false,
            list:[1,2,3],
            listLoading:false,
            finished:true,
            currentPage:1,
            pageSize:20,
            myAccount:{},
            jineActivity:-1,
            rebateBalance:0.00
        }
    },
    components: {
        [NavBar.name]: NavBar,
        [Toast.name]: Toast,
    },
    methods:{
        onClickLeft(){
            window.android.finishThisPage();
        },
        chooseAmount(amount){
            this.jineActivity=amount
        },
        fanliBack(){
            if(this.jineActivity<0){
                Toast('请选择要提现的返利金额')
                return;
            }
            var coin= this.jineActivity*100;
            withdrawRebate(coin).then((res)=>{
                if(res.code!=0){
                    Toast(res.message)
                }else{
                    Toast('兑换成功，请关注微信到账信息')
                }
            })
        },
    },
    mounted () {
        var _this=this;
        getMyAccount().then((res)=>{
            _this.rebateBalance=res.data.rebateBalance;
        })
    },
})
</script>
