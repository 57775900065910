<template>
    <div class="recharge"> 
        <div class="topBack" style="height:250px;"> 
            <div class="nav-bar">   
                        
            </div>
            <div class="flex-box" style="flex-direction: column;"> 
                <div class="flex-box-center" style="width:100%">
                    <div class="flex-box-center-col phone-div"> 
                        <svg style="height:25px;width: 25px;" t="1656395611162" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2250" width="48" height="48"><path d="M512 884.053333c-40.96 0-75.093333-34.133333-75.093333-75.093333s34.133333-75.093333 75.093333-75.093333 75.093333 34.133333 75.093333 75.093333S552.96 884.053333 512 884.053333zM512 771.413333c-20.48 0-37.546667 17.066667-37.546667 37.546667s17.066667 37.546667 37.546667 37.546667c20.48 0 37.546667-17.066667 37.546667-37.546667S532.48 771.413333 512 771.413333z" p-id="2251" fill="#ffffff"></path><path d="M679.253333 993.28c-3.413333 0-6.826667 0-10.24-3.413333-40.96-23.893333-109.226667-23.893333-109.226667-23.893333l0 0L276.48 965.973333c-54.613333 0-98.986667-44.373333-98.986667-98.986667L177.493333 139.946667c0-54.613333 44.373333-98.986667 98.986667-98.986667l471.04 0c54.613333 0 98.986667 44.373333 98.986667 98.986667l0 727.04c0 54.613333-44.373333 98.986667-98.986667 98.986667-10.24 0-17.066667-6.826667-17.066667-17.066667s6.826667-17.066667 17.066667-17.066667c34.133333 0 61.44-27.306667 61.44-61.44L808.96 139.946667c0-34.133333-27.306667-61.44-61.44-61.44L276.48 78.506667c-34.133333 0-61.44 27.306667-61.44 61.44l0 727.04c0 34.133333 27.306667 61.44 61.44 61.44l283.306667 0c3.413333 0 78.506667 0 126.293333 30.72 10.24 6.826667 10.24 17.066667 6.826667 23.893333C692.906667 989.866667 686.08 993.28 679.253333 993.28z" p-id="2252" fill="#ffffff"></path><path d="M590.506667 150.186667l-153.6 0c-10.24 0-17.066667-6.826667-17.066667-17.066667s6.826667-17.066667 17.066667-17.066667l153.6 0c10.24 0 17.066667 6.826667 17.066667 17.066667C607.573333 139.946667 600.746667 150.186667 590.506667 150.186667z" p-id="2253" fill="#ffffff"></path></svg>
                        <input v-model="phoneNum" @keyup="phoneInput()" oninput="if(value.length>11)value=value.slice(0,11)" type="number" placeholder="请输入手机号" class="phone-input" />
                    </div>
                </div>
                <div class="flex-box" style="margin-top: 10px; height:30px;width:85%;justify-content: flex-end;margin-left: 7.5%;"> 
                    <span style="color:#FFFFFF;font-size: 14px;">{{sp_data}}</span>
                </div>
                                                            
            </div>
        </div>


        <div class="flex-box recharge-div" style="flex-direction: column;margin-top: -80px;"> 

        <van-tabs v-model:active="active">
            <van-tab title="话费快充">
                <div class="flex-box" style="align-items: center;height:20px;padding: 10px;margin-left:10px">
                    <span class="title">话费充值</span>
                    <span class="content">极速到账</span>
                </div>
                <div class="flex-box" style="flex-wrap:wrap;margin-top: 10px;">
                    <div class="flex-box-center" style="width:33.33%">
                        <div :class="{'recharge-item-checked':currentGoodId===2}" class="flex-box-center recharge-item" @click="goodChecked(2,49.7)">
                            <div class="recharge-jine"><span>50</span><span style="font-size:10px">元</span></div>
                            <div style="font-size:10px">优惠价49.3</div>
                        </div>
                    </div>
                    <!-- <div class="flex-box-center" style="width:33.33%">
                        <div class="flex-box-center recharge-item" @click="goodChecked($event,2,100)">
                            <div class="recharge-jine"><span>100</span><span style="font-size:10px">元</span></div>
                            <div style="font-size:10px">优惠价99.7</div>
                        </div>
                    </div> -->
                    <!-- <div class="flex-box-center" style="width:33.33%">
                        <div class="flex-box-center recharge-item" @click="goodChecked($event,2,200)">
                            <div class="recharge-jine"><span>200</span><span style="font-size:10px">元</span></div>
                            <div style="font-size:10px">优惠价197.9</div>
                        </div>
                    </div> -->
                </div>
            </van-tab>
            <van-tab title="话费慢充">
                <div class="flex-box" style="align-items: center;height:20px;padding: 10px;margin-left:10px">
                    <span class="title">话费慢充</span>
                    <span class="content">优惠多多</span>
                </div>
                <div class="flex-box" style="flex-wrap:wrap;margin-top: 10px;">
                    <!-- <div class="flex-box-center" style="width:33.33%">
                        <div class="flex-box-center recharge-item">
                            <div class="recharge-jine"><span>50</span><span style="font-size:10px">元</span></div>
                            <div style="font-size:10px">优惠价50</div>
                        </div>
                    </div>
                    <div class="flex-box-center" style="width:33.33%">
                        <div class="flex-box-center recharge-item">
                            <div class="recharge-jine"><span>100</span><span style="font-size:10px">元</span></div>
                            <div style="font-size:10px">优惠价100</div>
                        </div>
                    </div> -->
                    <div class="flex-box-center" style="width:33.33%">
                        <div :class="{'recharge-item-checked':currentGoodId===3}" class="flex-box-center recharge-item"  @click="goodChecked(3,200)">
                            <div class="recharge-jine"><span>200</span><span style="font-size:10px">元</span></div>
                            <div style="font-size:10px">优惠价200</div>
                        </div>
                    </div>
                </div>
            </van-tab>
        </van-tabs>



            <div class="flex-box tishi-div">
                <div class="flex-box-center-col"> 
                    <svg t="1656401506059" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4335" width="16" height="16"><path d="M661.333333 661.397333c-11.157333 2.645333-11.157333 2.645333-12.010666 3.754667l-0.768 0.597333 0.576-0.533333a21.226667 21.226667 0 0 1 12.202666-3.84zM384 725.333333h256v-42.602666c0-7.253333 3.626667-13.653333 9.130667-17.493334 2.816-2.688 5.418667-5.269333 8.768-8.661333 5.546667-5.632 11.946667-12.202667 23.765333-24.448 18.965333-19.626667 23.893333-24.746667 29.568-30.4a282.944 282.944 0 0 0 83.157333-200.768c0-37.290667-7.274667-74.24-21.546666-108.693333a21.333333 21.333333 0 1 1 39.445333-16.32 327.04 327.04 0 0 1 24.768 125.013333c0 86.037333-33.578667 168.874667-95.658667 230.954667-5.397333 5.397333-10.346667 10.496-28.906666 29.717333A3223.168 3223.168 0 0 1 682.666667 692.138667l-0.021334 79.466666c3.2 65.493333-16.448 103.061333-85.034666 103.061334h-174.293334c-66.688 0-89.664-42.88-81.984-103.36v-82.133334c-14.208-10.432-33.834667-29.290667-61.824-57.258666-127.552-127.573333-127.552-334.357333 0-461.909334a325.589333 325.589333 0 0 1 230.954667-95.658666c42.858667 0 85.354667 8.384 125.013333 24.789333a21.333333 21.333333 0 0 1-16.32 39.424 284.373333 284.373333 0 0 0-108.693333-21.546667c-74.837333 0-146.794667 29.184-200.789333 83.178667-110.890667 110.869333-110.890667 290.666667 0 401.536 13.184 13.184 28.608 27.989333 40.938666 39.210667 6.656 6.08 12.224 10.944 16.298667 14.229333 1.92 1.536 3.370667 2.645333 4.245333 3.2-2.069333-1.344-3.285333-1.941333-8.490666-1.941333a21.333333 21.333333 0 0 1 21.333333 21.333333V725.333333z m0 42.666667v4.693333l-0.192 2.837334c-5.248 38.997333 3.904 56.469333 39.509333 56.469333h174.293334c37.333333 0 44.586667-13.866667 42.389333-59.349333V768H384z m0 128h256v42.666667H384v-42.666667z" fill="#3D3D3D" p-id="4336"></path></svg>                
                    <span style="margin-left:2px">温馨提示：</span>
                </div>
                <span>· 请仔细核对手机号码，话费充值不支持退单哦~</span>
                <span>· 请勿使用副卡手机号充值~</span>
            </div>
        </div>

        <div class="recharge-div" style="margin-top:10px;padding-bottom: 0px;"> 
                <div>
                    <van-coupon-cell :coupons="coupons" :chosen-coupon="chosenCoupon" @click="showPopup" />
                    <van-popup  v-model:show="showList" position="bottom" style="height: 50%; padding-top: 4px;">
                        <van-coupon-list :show-exchange-bar="false"  :coupons="coupons" :chosen-coupon="chosenCoupon" :disabled-coupons="disabledCoupons" @change="onChange" @exchange="onExchange" />
                    </van-popup>
                </div>
        </div>

        <!-- <div @click="toSupport()" class="flex-box recharge-div" style="height:auto;margin-top: 10px;overflow: hidden;padding-bottom: 0px;"> 
            <img style="height:100%;width:100%" src="@/assets/image/tuiguangad.jpg"/>
        </div> -->

        <div class="flex-box recharge-div" style="margin-top:10px">
            <div class="flex-box tishi-div" style="flex-direction: column;"> 
                <span style="font-weight:400">注意事项</span>
                <span class="import-span">重要：请不要使用副卡手机号充值，如发生充值未到账情况官方将无法核实并退款。</span>
                <span>1.本业务为话费专享优惠，支付成功后1小时内到账，请留意【充值成功通知】短信或微信推送消息。</span>
                <span>2.因运营商维护等因素，部分订单实际到账时间略有延迟，请联系客服咨询。</span>
                <span>3.月初月末充值量较大，如充值失败请隔天再试。</span>
                <span>4.三网均可充值，没有地区限制，极小部分地区充值可能会失败，充值失败的订单将会自动退款。</span>
                <span>5.充值没有次数限制，同一号码如果短时间内多次充值，可能会影响到账速度。</span>
                <span>6.话费为虚拟充值类商品，携号转网、副卡号码、虚拟号暂无法充值。如果您遇到充错好、空号的情况，不支持退单。请仔细核对充值账号。</span>
                <span>7.话费充值为代缴业务，如需开局发票请联系运营商客服处理。充值充值后，15个工作日内支持售后处理，超过售后日期不予处理，请提前知晓。</span>
            </div>
        </div>

        <div style="padding-bottom:100px"></div>

        <div class="submit-bar">
            <div class="flex-box-center" style="width:100%;height:100%;justify-content: flex-start;"> 
                <div class="flex-box-center flex-col" style="width:60px" @click="toSupport()"> 
                    <img style="height:25px;width:25px" src="@/assets/image/kefu-dark.png"/>
                    <span style="font-size:12px">客服</span>
                </div> 
                <div class="flex-box-center flex-col" style="width:60px" @click="toSupport()"> 
                    <img style="height:25px;width:25px" src="@/assets/image/order.png"/>
                    <span style="font-size:12px">订单查询</span>
                </div> 
                <div class="flex-box-center submit-btn" style="margin-right:20px;margin-left:auto">
                    <div class="flex-box-center left flex-row">
                        <span style="font-size:12px">优惠价</span>
                        <span style="font-size:16px">￥{{currentGoodPrice}}</span>
                    </div>
                    <div @click="choosePayType()" class="flex-box-center right">立即充值</div>
                </div>
            </div>
        </div>
    </div>
      <van-action-sheet v-model:show="show" title="请选择支付方式">
        <div class="flex-box flex-col" style="margin-left:30px">
            <div class="flex-box-center-col sheet-content" @click="rechargeSubmit(1)"> 
              <img style="height:30px;width:30px" src="@/assets/image/wechatPay.png"/> <span style="margin-left:10px">微信支付</span>
            </div >
            <van-divider style="margin:0"/>
            <div class="flex-box-center-col sheet-content"  @click="rechargeSubmit(2)"> 
              <img style="height:30px;width:30px" src="@/assets/image/alipay.png"/> <span style="margin-left:10px">支付宝支付</span>
            </div>
        </div>
    </van-action-sheet>
    <van-overlay :show="overlay_show" @click="overlay_show = false" >
        <div class="overlay-div"> 
            <van-loading size="24px" vertical>跳转支付中...</van-loading>
        </div>

    </van-overlay>
</template>
<style scoped>
    .overlay-div{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
    .recharge{
        background: #F4F4F4;
        width: 100%;
        min-height: 100vh;
    }
    .nav-bar{
        height: 8vh;
    }
    .phone-input{
        border:none;   
        background: transparent;
        font-size: 20px;
        height: 30px;
        color: #FFF;
        width: 90%;
        margin-left: 5px;
    }


    input::-webkit-input-placeholder{
        color:#FFA9AD;
    }

    .phone-div{
        border:none; 
        border-bottom:1px solid #FFF;
        width: 85%;
        padding: 5px;
        justify-content: flex-start;
    }
    .recharge-div{
        background: #FFF;
        border-radius: 5px;
        height: auto;
        width: 92%;
        margin-left: 4%;
        padding-bottom:20px;
        overflow: hidden;
    }

    .title{
        font-weight: 600;
        font-size: 14px;
    }

    .content{
        margin-left: 5px;
        font-size: 12px;
        color: #A2A2A2;
    }

    .recharge-item{
        flex-direction: column;
        border:1px solid #FB5062;
        height: 60px;
        width: 90px;
        border-radius: 5px;
        background: #FFFFFF;
        color:#FB5062;
    }

    .recharge-item-checked{
        flex-direction: column;
        border:1px solid #FB5062;
        color:#FFFFFF;
        background: #FB5062;
    }

    .recharge-jine{
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 2px;
    }

    .tishi-div{
        margin-top: 20px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 12px;
        color:#707070;
        flex-direction: column;
        font-weight: 400;
        letter-spacing: 1px;
    }

    .tishi-div span{
        margin-bottom:3px;
    }

    .import-span{
        color:red
    }

    .submit-bar{
        position: fixed;
        width: 100%;
        bottom: 0;
        height: 80px;
        background: #FFF;
    }

    .submit-btn{
        overflow: hidden;
        border: none;
        border-radius: 30px;
        height: 45px;
        width: 240px;
    }

    .submit-btn .left{
        width: 100%;
        height: 100%;
        background: #343434;
        color: #EED1A7;
        font-size: 14px;
    }

    .submit-btn .right{
        width: 100%;
        height: 100%;
        background:linear-gradient(#FF2717,#FF1176);
        color:#FFF
    }

    .topBack{
        background-image: url('@/assets/image/sale.jpg');
        background-size: 100%;
    }

    .sheet-content{
        height:50px;
    }

</style>
<script>
import { NavBar,ActionSheet,Divider,Toast } from 'vant';
import {rechargeApi,getMobileInfo} from '@/js/util/mgRequest.js'
import { Overlay } from 'vant';
import { Loading } from 'vant';
import { CouponCell, CouponList,Popup} from 'vant';
import { Tab, Tabs } from 'vant';
export default({
    components: {
        [NavBar.name]: NavBar,
        [ActionSheet.name]: ActionSheet,
        [Divider.name]: Divider,
        [Toast.name]: Toast,
        [Overlay.name]: Overlay,
        [Loading.name]: Loading,
        [CouponCell.name]: CouponCell,
        [CouponList.name]: CouponList,
        [Popup.name]: Popup,
        [Tab.name]: Tab,
        [Tabs.name]: Tabs,
    },
    data(){
        return{
            show:false,
            phoneNum:'',
            sp_data:'',
            overlay_show:false,
            coupons : [],
            showList:false,
            chosenCoupon:-1,
            shopChecked:0,
            currentGoodId:-1,
            currentGoodPrice:0.00
        }
    },
    mounted(){
        document.title="省钱365-优惠充值";
    },
    methods:{
        choosePayType(){
            const regex = /^1[3456789]\d{9}$/;
            if(!regex.test(this.phoneNum)){
                Toast('请输入正确的手机号');
                return;
            }
            var cid=this.currentGoodId;
            if(cid===-1){
                Toast('请选择要充值的商品~');
                return;
            }
            this.show=true;
        },
        rechargeSubmit(payType){
            this.show=false;
            this.overlay_show=true;
            var phoneNum=this.phoneNum;
            var cid=this.currentGoodId;
            var data={
                payType:payType,
                phoneNum:phoneNum,
                goodId:cid
            }

            rechargeApi(data).then((res)=>{
                if(payType==1){
                    window.location.href=res.data;
                }else if(payType==2){
                    document.write(res.data)
                }
            })
        },
        toSupport(){
            window.location.href="https://support.qq.com/product/416164";
        },
        phoneInput(){
            var _this=this;
            const regex = /^1[3456789]\d{9}$/;
            if(regex.test(this.phoneNum)){
                getMobileInfo(this.phoneNum).then((res)=>{
                     _this.sp_data=res.data.province+res.data.city+res.data.sp;
                })
            }
        },
        onChange(index) {
            
            this.showList = false;
            this.chosenCoupon = index;
        },
        showPopup(){
            this.showList=true;
        },
        goodChecked(id,price){
            this.currentGoodId=id
            this.currentGoodPrice=price
        }
    },
    computed:{
        myGoodChecked(){
            return function(id){
                var cid=this.currentGoodId
                return id==cid;
            }
        }
    }
})
</script>