<template>
    <van-nav-bar title="邀请记录" left-text="" left-arrow @click-left="onClickLeft"/>
    <van-list v-model:loading="listLoading" :finished="finished" finished-text="没有更多了" @load="onLoad" style="padding-bottom: 25vw;" >
          <div class="flex-box" style="flex-wrap: wrap;margin-top: 1vw;" > 
            <div class="flex-box-center" style="width:100%"> 
                <div class="flex-box-center" style="width: 100%;font-weight: 600;">手机号</div>
                <div class="flex-box-center" style="width: 100%;font-weight: 600;">奖励</div>
                <div class="flex-box-center" style="width: 100%;font-weight: 600;">时间</div>
            </div>
            <div v-for="item in list" :key="item.newCustPhone" class="flex-box-center" style="width: 100%;margin-top: 12px;">
                <div class="flex-box-center" style="width: 100%;font-size: 14px;">{{convertPhone(item.newCustPhone)}}</div>
                <div class="flex-box-center" style="width: 100%;font-size: 14px;">邀请获得金币:<span style="color:red;font-size: 14px;">{{item.totalCoin}}</span> </div>
                <div class="flex-box-center" style="width: 100%;font-size: 14px;">{{item.createTime.slice(0,10)}}</div>

            </div>
    </div>
    </van-list>
</template>
<script>
import { NavBar } from 'vant';
import { List } from 'vant';
import { getMyInviteList} from '@/js/util/mgRequest.js'

export default({
    data() {
        return {
            loading:false,
            list:[],
            listLoading:false,
            finished:false,
            currentPage:1,
            pageSize:20
        };
    },
    components: {
        [NavBar.name]: NavBar,
        [List.name]: List,
    },
    methods:{
        onLoad(){
            var _this=this;
            var pageNum=_this.currentPage;

            getMyInviteList(pageNum,20).then((res)=>{
              if(_this.currentPage>=res.pages){
                    _this.finished=true;
                }
                for(var index=0;index<res.data.length;index++){
                    _this.list.push(res.data[index])
                }
                _this.currentPage++;
                _this.listLoading = false;
            })
        },
        onClickLeft(){
            window.android.finishThisPage();
        }
    },
    computed:{
        convertPhone:function(){
            return function(phone){
               var temp= phone.slice(3,7);
               var newphone= phone.replace(temp,'***')
               return newphone
            }
        }
  }
})
</script>
