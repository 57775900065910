<template>
  <div>
    <div  style="height: 18vw;background-color: #eb4035" >
      <div style="position:fixed;top:0;z-index: 999; height: 10vw;width: 100%; left:0;background-color: #eb4035"></div>
      <div class="flex-box-center" style="height: 18vw;margin-top: 10vw;">
      <div style="margin-left: 2.8vw;">
        <img style="height: 32px; width: 32px;" src="@/assets/image/logo.png" />
      </div>
      <div class="flex-box-center center search-box" @click="toSearch()" style="margin-left: 2vw">
        <img
          style="height: 4.5vw; width: 6vw; margin-right: 1.5vw"
          src="@/assets/image/search.png"
        />
        <span style="color: #ffffff; font-size: 3.5vw">输入关键字或粘贴标题搜索优惠</span
        >
      </div>
      <div style="margin-left: 2vw" @click="toKefu()">
        <img
          style="height: 7.5vw; width: 7.5vw; margin-right: 1.5vw"
          src="@/assets/image/kefu.png"
        />
      </div>
    </div>
    <!-- <div class="flex-box">
        <span style="color:#ffffff;font-size:3.2vw;margin-left: 5vw;">1.复制</span>
        <div class="radius-div" style="margin-left: 1vw;"><img style="height: 100%; width:100%; margin-right: 1.5vw" src="@/assets/image/taobao.png" /></div>
        <div class="radius-div" style="margin-left: 1vw;"><img style="height: 100%; width: 100%; margin-right: 1.5vw" src="@/assets/image/jingdong.png" /></div>
        <span style="color:#ffffff;font-size:3.2vw;margin-left: 1vw;">商品标题</span><span style="color:#ffffff;font-size:3.2vw;margin-left: 5vw;">2.搜索商品优惠券</span>
    </div> -->
  </div>
    <div>
        <van-tabs
        title-active-color="#FFFFFF"
        title-inactive-color="#C9C9C9"
        color="#FFCF36"
        background="#eb4035"
        v-model:active="active"
        sticky
        swipeable
        shrink
        @change="tabChange"
        offset-top="8vw"
        @click-tab="onClickTab">
            <!-- <van-tab v-for="tab in tabList" :title="tab.title">
                <component  :is="componentName"></component>
            </van-tab> -->
            <van-tab title="今日精选">
              <IndexComponent id="-1"/>
            </van-tab>
            <van-tab title="女装">
              <ShoppingComponent id="20005"/>
            </van-tab>
            <van-tab title="男装">
              <ShoppingComponent id="20009"/>
            </van-tab>
            <van-tab title="生鲜">
              <ShoppingComponent id="20008"/>
            </van-tab>
            <van-tab title="箱包">
              <ShoppingComponent id="20011"/>
            </van-tab>
            <van-tab title="酒类">
              <ShoppingComponent id="20012"/>
            </van-tab>
            <van-tab title="书籍">
              <ShoppingComponent id="20015"/>
            </van-tab>
            <van-tab title="零食">
              <ShoppingComponent id="20018"/>
            </van-tab>
            <van-tab title="3C数码">
              <ShoppingComponent id="20000"/>
            </van-tab>
            <van-tab title="工具">
              <ShoppingComponent id="20013"/>
            </van-tab>
            <van-tab title="茶">
              <ShoppingComponent id="20004"/>
            </van-tab>
      </van-tabs>
    </div>
    <!-- <div class="login-box flex-box-center-col" @click="toLogin()" v-show="false">
      <div class="flex-box-center-col" style="width: 100%">
        <span style="color: #FFFFFF;font-size:12px; margin-left:auto">登录开启省钱之旅</span>
        <div class="btn-login flex-box-center">立即登录</div>
      </div>
    </div> -->
    
  </div>
</template>
<style scoped>
      .radius-div{
        border-radius: 50%;
        background: #FFFFFF;
        height: 5vw;
        width: 5vw;
    }

    .login-box{
      height: 40px;
      width: 100%;
      background: #282E35;
      position: fixed;
      bottom: 50px;
      opacity:0.9
    }

    .btn-login{
      margin-left: auto;
      margin-right: 15px;
      border-radius: 15px;
      height:25px;
      width:120px;
      background-image: linear-gradient(to right, #f9d423 0%, #ff4e50 100%);
      color: #FFFFFF;
    }
</style>
<script>
import "@/assets/css/home/home.css"
import IndexComponent from '@/components/home/IndexComponent.vue'
import ShoppingComponent from '@/components/home/ShoppingComponent.vue'
import { Tab, Tabs } from "vant"
import { autoLogin} from '@/js/util/mgRequest.js'
import { isLogin} from '@/js/util/common.js'

export default {
  data() {
    return {
      tab1value: '0',
      tabList: [
      ],
      componentName:'IndexComponent',
      active:0,
      weLogin:false
    };
  },
  components: {
    IndexComponent,
    ShoppingComponent,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
  },
  methods:{
      onClickTab(data){
        console.log(data)
        if(data.name==0){
            this.componentName='IndexComponent'
        }else{
            this.componentName='ShoppingComponent'
        }
      },
      toSearch(){
        window.android.jumpToCommonActivity("https://app.pxxpxxpxx.com/#/search");
        //window.android.jumpToCommonActivity("search");
      },
      tabChange(name,title){
        this.active=name;
        if(name>0){
          this.componentName='ShoppingComponent'
        }else{
          this.componentName='IndexComponent'      
        }
      },
      toLogin(){
        window.android.jumpToCommonTransActivity("https://app.pxxpxxpxx.com/#/login");
      },
      toKefu(){
        window.android.jumpToCommonTransActivity("https://work.weixin.qq.com/kfid/kfc20f6aea03538ec0d");
      }
  },
  async mounted () {
    this.weLogin=await isLogin();
    
    autoLogin().then((res)=>{
      if(res.code===0){
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("userInfo", JSON.stringify(res.data));
          window.android.refreshToken(res.data.token)
      }
    })

  },
}
</script>