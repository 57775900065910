<template>
    <div class="shopDetail">
        <div style="width: 100%;">
            <van-swipe :autoplay="3000" lazy-render>
                <van-swipe-item v-for="ban in shopDetai.imgs" :key="ban" >
                    <div class="shop-banner" :style="'background-image:url(' + ban + ');'">
                </div>
                </van-swipe-item>
            </van-swipe>
        </div>
        <div  style="margin:10px;border-radius: 5px;background:#FFFFFF;padding: 10px;">
                <div class="flex-box-center-col good-price"> 
                    <span style="color:#f2270c;font-size: 14px;color: #f2270c;font-weight: 600;">劵后￥</span> <span style="color:#f2270c;font-size: 22px;color: #f2270c;font-weight: 600;">{{((shopDetai.price-shopDetai.cos_fee)*0.01).toFixed(2)}}</span>
                    <span class="secondary-font-color" style="font-size:14px;margin-left: auto;">月销2200万+</span>
                </div>
                <div class="shop-title"> 
                    <span>{{shopDetai.title}}</span>
                </div>

        </div>
        

        <div class="" style="margin:10px;border-radius: 5px;background:#FFFFFF;padding: 10px;"> 
                <div class="flex-box-center-col" style="margin-top:8px"> 
                    <div class="flex-box"> 
                        <img style="width:40px;width:40px" src="@/assets/image/new.png" />
                    </div>
                    <div class="flex-box" style="margin-left:10px"> 
                        <span style="font-size:16px;">{{shopDetai.shop_name}}</span>
                    </div>
                    <div class="flex-box flex-col secondary-font-color" style="margin-left:auto;font-size:12px;"> 
                        <span>商品体验分：{{product_score}}</span>
                        <span>物流体验分：{{logistics_score}}</span>
                        <span>商户服务分：{{service_score}}</span>
                    </div>
                </div>
        </div>
        <div class="good-detail"  style="margin-bottom:80px"> 
            <van-collapse v-model="activeNames">
                <van-collapse-item title="图文详情" name="1">
                    <div v-for="ban in shopDetai.imgs" :key="ban">
                        <img style="width:100%" :src="ban" />
                    </div>
                    
                </van-collapse-item>
            </van-collapse>
        </div>

        <div class="flex-box-center-col buy-div"> 
            <div class="flex-box-center  flex-col" style="width:60px">
                <img style="width:25px;height:25px" src="@/assets/image/index.png" />
                <span class="secondary-font-color" style="font-size:12px">首页</span>
            </div>
            <div class="flex-box-center flex-col" style="width:60px">
                <img style="width:25px;height:25px" src="@/assets/image/shoucang.png" />
                <span class="secondary-font-color" style="font-size:12px">收藏</span>
            </div>
            <div class="flex-box-center" style="height:40px;width:240px;margin-left: auto;margin-right: 20px;">
                <div style="width:100%;height:100%" v-bind:style="{backgroundImage:'url(' + bg + ')',backgroundRepeat:'no-repeat',backgroundSize:'100% 100%'}">
                    <div class="flex-box-center" style="width:100%;height:100%" @click="jumpDouyin(shopDetai.detail_url)">
                        <div class="flex-box-center" style="width:100%"><span style="color:#e02e24;font-size: 13px;">直降{{(shopDetai.cos_fee*0.01).toFixed(2)}}元</span> </div>
                        <div class="flex-box-center" style="width:100%"><span style="color:#FFFFFF;font-size: 13px;">立即购买></span> </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
    .shopDetail{
        min-height: 100vh;
        background: #F8F8F8;
    }

    .shop-banner{
        height: 40vh;
        width: 100vw;
        background-size: 100% 100%;
        background-color: antiquewhite;
        background-repeat: no-repeat;
    }

    .shop-title{
        margin-top:5px;
        color:#262626;
        font-size: 16px;
        font-weight: 600;
    }

    .good-info{ 
        color:#a6a6a6;
        font-size: 14px;
    }

    .good-price{
        line-height: 24px;  
    }

    .shop-info{
        margin-top:5px;
        border-radius: 10px;
        background: #FFFFFF;
        min-height: 80px;
    }

    .good-detail{
        overflow: hidden;
        margin-top:10px;
        border-radius: 10px;
        background: #FFFFFF;
    }

    .buy-div{
        width: 100%;
        position: fixed;
        height: 60px;
        bottom: 0px;
        background: #FFFFFF;
    }
</style>
<script>
import { Swipe, SwipeItem } from "vant";
import { getIndexProductDetail,getIProductLink } from '@/js/util/mgRequest.js'
import { Collapse, CollapseItem } from 'vant';
import { isLogin} from '@/js/util/common.js'

export default({
    data() {
        return {
        bannerList:[],
        activeNames:['1'],
        bg: require('@/assets/image/temai.png'),
        shopDetai:{},
        product_score:5.00,
        logistics_score:5.00,
        service_score:5.00
        };
    },
    components: {
        [Swipe.name]: Swipe,
        [SwipeItem.name]: SwipeItem,
        [Collapse.name]: Collapse,
        [CollapseItem.name]: CollapseItem
    },
    mounted(){
        // getIndexProductDetail("3455938529937469000").then((res)=>{
        //     console.log(res)
        // })
        var _this=this;
        var productId = this.$route.query.productId;
        getIndexProductDetail(productId).then((res)=>{
            _this.shopDetai=res.data;
            _this.product_score=res.data.shop_total_score.product_score.score
            _this.logistics_score=res.data.shop_total_score.logistics_score.score
            _this.service_score=res.data.shop_total_score.service_score.score
        })
    },
    methods:{
        async jumpDouyin(url){
            var _isLogin=await isLogin();
            if(!_isLogin){
                window.android.jumpToCommonTransActivity("https://app.pxxpxxpxx.com/#/login");
                return;
            }
            var productId = this.$route.query.productId;
            getIProductLink(encodeURIComponent(url),productId).then((res)=>{
                window.android.jumpToDy(res.data.dy_deeplink)
          })
        }
    }
})
</script>
