<template>
  <div class="my-order">
      <div class="header">
          <div class="nav flex-box-center">
              <div class="flex-box-center" style="flex:1">
                  <!-- <van-icon style="margin-left: 15px; margin-right:auto" name="arrow-left" /> -->
                  <span style="width:100%;text-align: center;font-size: 20px;">购物车</span>
              </div>
          </div>
      </div>
      <!-- <div> 
          <van-notice-bar left-icon="question-o" text="订单同步可能会有5-10分钟的延迟 " />
      </div> -->
      <div class="order-div flex-box-center"> 
        <van-list v-model:loading="listLoading" :finished="finished" finished-text="没有更多了" @load="onLoad" style="padding-bottom: 25vw;width: 100%;" >
          <div class="flex-box-center" style="margin-top: 1vw;width: 100%;flex-direction: column;" > 
        <div v-for="product in productList" :key="product.sku" class="flex-box-center"  style="width:100%;flex-direction: column;margin-top: 8px;" @click="jumpToDetail(product.product_id)">
          <div class="order-card" style="width:100%">
              <div style="padding:10px"> 
                  <div class="order-title flex-box-center-col flex-row">
                      <div  class="qudao-img"><img style="height:100%;width:100%" src="@/assets/image/dy.png"/></div>
                      <div class="shop-name"><span>{{product.shopName}}</span></div>
                  </div>
                  <div class="order-content flex-box"> 
                      <div class="order-img flex-box">
                          <img style="width:100%;height:100%" :src="product.img"/>
                      </div>
                      <div class="flex-box" style="flex-direction: column;">
                          <div> 
                              <span class="good-name">{{product.title}}</span>
                          </div>
                          <div class="order-price"> 
                            <span style="color:#f2270c;font-size: 12px;color: #f2270c;">劵后￥</span> <span style="color:#f2270c;font-size: 16px;color: #f2270c;">{{((product.price-product.cosFee)*0.01).toFixed(2)}}</span>
                            <span style="color:#f2270c;font-size: 12px;color:#a6a6a6;margin-left: 20px;"><del>{{((product.price)*0.01).toFixed(2)}}</del></span>
                        </div>
                          <div style="margin-top:10px;padding-left: 5px" class="flex-box-center-col">
                            <div class="flex-box-center vip-card-left">VIP</div><div class="flex-box-center vip-card-right">返利{{(product.cosFee*0.01).toFixed(2)}}</div> 
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
    </div>
    </van-list>
        
        

      </div>
  </div>
</template>
<style scoped>
  .my-order{
      min-height: 100vh;
      background-color: #F3F3F3;
  }

  .header{
      background-color: #ed5149;
      height: 80px;
  }

  .nav{
      padding-top: 5vh;
      color: #FFF;
  }

  .nav div{
      display: flex;
      justify-content: center;
  }

  .order-div{
      padding: 10px;
  }

  .order-card{
      background-color: #FFFFFF;
      width: 100%;
      border-radius: 10px;
      overflow: hidden;
  }

  .qudao-img{
      width:14px;
      height:14px
  }

  .shop-name{
      font-size:14px;
      color: #434343;
      margin-left: 5px;
      flex:1;
      text-overflow:ellipsis;
      font-weight: 600;
  }

  .shop-name span{
      width:140px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;display: block;
  }

  .order-status{
      font-size:12px;
      color:#8B8D8C;
      margin-left: 5px;
  }

  .order-img{
      width:100px;
      height:100px;
      border-radius: 5px;
      overflow: hidden;
  }

  .good-name{
      color: #434343;
      font-size: 12px;
      font-weight: 400;
      margin-left: 10px;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      word-break: break-all;
      -webkit-box-orient: vertical;
      overflow: hidden;
  }

  .order-price{
      width:100%;
      font-size:14px;
      color: #434343;
      margin-top: 15px;
      margin-left: 10px;
      font-weight: 600;
      color: red;
  }

  .order-content{
      margin-top: 8px;
  }

  .order-jl{
      background-color: #fef6f4;
      height: 40px;
  }

  .btn-order-detail{
      border: 1px solid;
      border-radius: 15px;
      height: 28px;
      width: 80px;
      font-size: 12px;
      color:#FFF;
      background-color: #ef8f36;
  }


</style>
<script>
import { Icon } from 'vant';
import { DropdownMenu, DropdownItem } from 'vant';
import { NoticeBar } from 'vant';
import { getShopCarList } from '@/js/util/mgRequest.js'
import { List } from 'vant';

export default({
  data() {
      return {
          value1:0,
          option1:[
              { text: '全部订单', value: 0 },
              { text: '淘宝', value: 1 },
              { text: '京东', value: 2 },
              { text: '拼多多', value: 3 }
          ],
          productList:[],
          listLoading:false,
          finished:false,
          currentPage:1,
          pageSize:20
      };
  },
  components: {
      [Icon.name]: Icon,
      [DropdownMenu.name]: DropdownMenu,
      [DropdownItem.name]: DropdownItem,
      [NoticeBar.name]: NoticeBar,
      [List.name]: List,
  },
  methods:{
    onLoad(){
            var _this=this;
            var pageNum=_this.currentPage;

            getShopCarList(pageNum,20).then((res)=>{
                if(_this.currentPage>=res.pages){
                    _this.finished=true;
                }
                for(var index=0;index<res.data.length;index++){
                    _this.productList.push(res.data[index])
                }
                _this.currentPage++;
                _this.listLoading = false;
            })
        },
    jumpToDetail(productId){
        window.android.jumpToCommonActivity("https://app.pxxpxxpxx.com/#/shopDetail?productId="+productId);
    },
  }
})
</script>