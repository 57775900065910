<template>
    <div class="my-feedback">
        <div>
            <van-nav-bar title="意见反馈" left-text="" left-arrow @click-left="onClickLeft()" />
        </div>
        <div class="flex-box-center" style="margin-top:10px"> 
            <textarea class="my-textarea" placeholder="求吐槽，让我们做的更好..." rows="8" v-model="m_content"></textarea>
        </div>
        <div class="flex-box-center" style="margin-top:10px"> 
            <van-button @click="doFeedback()" round  block style="width:90%" color="linear-gradient(to right, #ff6034, #ee0a24)">
                立即提交
            </van-button>
        </div>
    </div>
</template>
<style scoped>
    .my-feedback{

    }
    .my-textarea{
        width:90%;
        overflow:hidden;
        border-radius: 5px;
        border-color:burlywood;
        padding: 10px;
    }
</style>
<script>
import { NavBar } from 'vant';
import { Button } from 'vant';
import { Cell, CellGroup } from 'vant';
import {sendFeedback} from '@/js/util/mgRequest.js'
import { Toast } from 'vant';
export default({
    data() {
        return {
            m_content:''
        };
    },
    components: {
        [Cell.name]: Cell,
        [CellGroup.name]: CellGroup,
        [Button.name]: Button,
        [NavBar.name]: NavBar,
        [Toast.name]: Toast,
    },
    methods:{
        doFeedback(){
            var content=this.m_content;
            if(content==""||content.length<10){
               Toast('请输入不少于10个字的内容，方便我们持续改进哦~');
               return;
            }
            sendFeedback(content).then(()=>{
                Toast.success('反馈成功，感谢您的宝贵的意见~');
            })
        },
        onClickLeft(){
            this.$router.go(-1);
        }
    }
})
</script>
