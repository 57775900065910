<template>
  <div>
    <!-- 优惠券单元格 -->
    <van-coupon-cell :coupons="coupons" :chosen-coupon="chosenCoupon" @click="showPopup" />
    <van-popup  v-model:show="show" position="bottom" style="height: 50%; padding-top: 4px;">
        <van-coupon-list :show-exchange-bar="false"  :coupons="coupons" :chosen-coupon="chosenCoupon" :disabled-coupons="disabledCoupons" @change="onChange" @exchange="onExchange" />
    </van-popup>
    <!-- 优惠券列表 -->
    <!-- <van-popup v-model="show" position="bottom">
      <van-coupon-list :coupons="coupons" :chosen-coupon="chosenCoupon" :disabled-coupons="disabledCoupons" @change="onChange" @exchange="onExchange" />
    </van-popup> -->
  </div>
</template>
<script>
const coupon = {
  id: '1', // 优惠券id
  discount: 0, // 折扣券 折值 整数 为0不显示折
  denominations: 2000, // 优惠券金额 单位分
  originCondition: 5000, // 满减规则金额 单位分 为0显示无门槛
  value: 12, // 折扣券优惠金额，单位分
  name: '新人大礼包', // 优惠券名称
  description: '喜欢你就用', // 描述信息
  reason: '订单未满200元', // 不可用原因，优惠券不可用时展示
  startAt: parseInt(1556337595530 / 1000), // 卡有效开始时间 (时间戳, 单位秒)
  endAt: parseInt(1559337595530 / 1000) // 卡失效日期 (时间戳, 单位秒)
}

import { CouponCell, CouponList,Popup} from 'vant';
export default {
  components: {
    [CouponCell.name]: CouponCell,
    [CouponList.name]: CouponList,
    [Popup.name]: Popup
  },
  data() {
    return {
        show:false,
      showList:false,
      chosenCoupon: -1,
      coupons: [coupon],
      disabledCoupons: [coupon]
    };
  },

  methods: {
    onChange(index) {
      this.showList = false;
      this.chosenCoupon = index;
    },
    onExchange(code) {
      this.coupons.push(coupon);
    },
    showCon(){
        this.showList=true
    },
    showPopup(){
        this.show=true;
    }
  }
};
</script>