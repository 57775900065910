<template>
    <div class="my-setting">
        <div>
            <van-nav-bar title="设置" left-text="" left-arrow @click-left="onClickLeft" />
        </div>
        <div class="setting-group">
            <van-cell-group title="">
                <van-cell title="账号与安全" is-link to="/userInfo"/>
                <van-cell title="消息推送" is-link  value="">
                    <template #right-icon>
                        <van-switch size="18" active-color="#ee0a24" inactive-color="#dcdee0" class="checked-btn" v-model="ts_checked" @update:model-value="onUpdateTsValue" />
                    </template>
                </van-cell>
                <van-cell title="权限设置" is-link  value="" @click="permissionSet()"></van-cell>
                <van-cell title="意见反馈" is-link  to="/feedback"/>
                <van-cell title="个性化商品推荐" is-link  value="">
                    <template #right-icon>
                        <van-switch size="18" active-color="#ee0a24" inactive-color="#dcdee0" class="checked-btn" v-model="tj_checked" @update:model-value="onUpdateTjValue"/>
                    </template>
                </van-cell>
            </van-cell-group>
        </div>
        <div class="setting-group">
            <van-cell-group title="">
                <van-cell title="查看《用户服务协议》" is-link to="/xieyi"/>
                <van-cell title="查看《隐私政策》" is-link to="/yinsi"/>
                <van-cell title="当前版本" :value="currentVersion"/>
                <van-cell title="清除缓存" is-link @click="removeCache()"/>
            </van-cell-group>
        </div>
        <div class="setting-group">
            <van-cell-group title="">
                <van-cell title="账号注销" is-link @click="cancelAccount()"/>
            </van-cell-group>
        </div>
        <div class="flex-box-center" style="margin-top:40px"> 
            <van-button :disabled="btnLogoutDisable" @click="logout()" round  block style="width:90%" color="linear-gradient(to right, #ff6034, #ee0a24)">
                退出登录
            </van-button>
        </div>

        <div class="flex-box-center" style="bottom:10px;position:absolute;text-align: center;width: 100%;"> 
            <span style="font-size:10px; color:#8B8D8C">{{qdFrom}}</span>
        </div>
    </div>
</template>
<style scoped>
    .my-setting{
        height: 100%;
        width: 100%;
        background-color: rgb(243, 243, 243);
	    position: fixed;
    }

    .setting-group{
        margin-top: 10px;
    }

    .checked-btn{

    }
</style>
<script>
import { NavBar } from 'vant';
import { Button } from 'vant';
import { Cell, CellGroup } from 'vant';
import { Toast } from 'vant';
import { Dialog } from 'vant';
import { Switch } from 'vant';
export default({
    data() {
        return {
            btnLogoutDisable:false,
            currentVersion:'',
            qdFrom:'',
            ts_checked:false,
            tj_checked:false
        };
    },
    components: {
        [Cell.name]: Cell,
        [CellGroup.name]: CellGroup,
        [Button.name]: Button,
        [NavBar.name]: NavBar,
        [Toast.name]: Toast,
        [Dialog.name]: Dialog,
        [Switch.name]: Switch
    },
    mounted () {
        const userInfo=localStorage.getItem("userInfo");
        if(userInfo==null||userInfo==""){
            this.btnLogoutDisable=true;
        }

        var bbh=window.android.getAppVersion();
        this.currentVersion=bbh;
        var laiyuan=window.android.getAppChannel();
        this.qdFrom=laiyuan;

        //推送、推荐开关配置
            var tsChecked= localStorage.getItem("tsChecked");
            if(tsChecked!=null&&tsChecked==="1"){
                this.ts_checked=true
            }else{
                this.ts_checked=false;
            }


        var tjChecked= localStorage.getItem("tjChecked");
            if(tjChecked!=null&&tjChecked==="1"){
                this.tj_checked=true
            }else{
                this.tj_checked=false;
            }
    },
    methods:{
        removeCache(){
            const toast = Toast.loading({
            duration: 0,
            forbidClick: true,
            message: '努力清理中...',
            });

            let second = 1;
            const timer = setInterval(() => {
                second--;
                if (second) {
                    
                } else {
                    clearInterval(timer);
                    Toast.clear();
                    Toast.success('清理成功');
                }
                }, 1000);
        },
        logout(){
            Dialog.confirm({
                message:
                    '确定要退出登录吗？',
                })
                .then(() => {
                    const token=localStorage.getItem("token");
                    localStorage.removeItem("userInfo")
                    localStorage.removeItem("token")
                    Toast.success('退出登录成功');
                    window.android.refreshToken(token)
                    window.android.finishThisPage();
                })
                .catch(() => {
                    // on cancel
                });

        },
        cancelAccount(){
            this.$router.push('/cancelAccount')
        },
        onClickLeft(){
            //this.$router.go(-1);
            window.android.finishThisPage();
        },
        onUpdateTsValue(){
            var checked=this.ts_checked==true?1:0;
            localStorage.setItem("tsChecked",checked)
        },
        onUpdateTjValue(){
            var checked=this.tj_checked==true?1:0;
            localStorage.setItem("tjChecked",checked)
        },
        permissionSet(){
            window.android.jumpPermission();
        }
    }
})
</script>
