<template>

    <div style="position:fixed;top:0;z-index: 999; height: 10vw;width: 100%; left:0;background-color: #FF2717"></div>
    <div style="width:100%;margin-top:8vw;background-color: #f43;"> 
        <img style="width:100%;height:100%" src="@/assets/image/discover-bg.jpg" />
    </div>
    <div style="background:#f43"> 
                <van-pull-refresh v-model="loading" @refresh="onRefresh" style="color:#FFFFFF" pulling-text="今天又买了啥" loosing-text="让我来瞧瞧  (✿◡‿◡)">
            <template #loading>
                    <img class="doge" src="@/assets/image/disLoading.gif" />
            </template>
         <van-list v-model:loading="listLoading" :finished="finished" finished-text="没有更多了" @load="onLoad" >
            <div class="flex-box ba" style="flex-direction: column;align-items: center;"> 
                <div  v-for="product in productList" :key="product" class="flex-box rank-card-div" @click="jumpToDetail(product.product_id)">
                <div class="flex-box">
                    <img :src="product.cover" style="width:150px;padding-left:10px;padding-top:5px;padding-bottom:5px" />
                </div>
                <div class="flex-box" style="flex-direction: column;width: 100%;padding: 10px;"> 
                    <div class="flex-box" style="height:30%;width: 100%;align-items: center;">
                        <img class="jiaobiao-img" src="@/assets/image/dy.png" /><div class="product-div" style="margin-left:5px">{{product.title}}</div>
                    </div>
                    <div class="flex-box" style="height:30%;width: 100%;align-items: center;">
                         <div class="flex-box-center vip-card-left">VIP</div> <div class="flex-box-center vip-card-right">返利{{(product.cos_fee*0.01).toFixed(2)}}</div> 
                    </div>
                    <div class="flex-box" style="height:30%;width: 100%;align-items: center;">
                        <div class="flex-box" style="width: 100%;align-items: center;"><span style="color:#F31A61;font-size:10px">到手价</span><span style="color:#F31A61;font-size:14px;font-weight: 600;">￥{{((product.price-product.cos_fee)*0.01).toFixed(2)}}</span></div> 
                    </div>
                    <div class="flex-box-center" style="height:8vw;width: 100%">
                        <div style="width:100%;height:100%" v-bind:style="{backgroundImage:'url(' + bg + ')',
                                backgroundRepeat:'no-repeat',
                                backgroundSize:'100% 100%'}">
                                <div class="flex-box-center" style="width:100%;height:100%">
                                    <div class="flex-box-center" style="width:100%"><span style="color:#e02e24;font-size: 13px;">直降{{(product.cos_fee*0.01).toFixed(2)}}元</span> </div>
                                    <div class="flex-box-center" style="width:100%"><span style="color:#FFFFFF;font-size: 13px;">立即购买></span> </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>   
        </van-list>
    </van-pull-refresh>
    </div>
</template>
<style scoped>

.product-div{
    color:#434343;
    font-size:14px;
    margin-left: 5px;
    width:90%;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-weight: 600;
}
    .ba{
        background-color: #f43;
        /* padding-bottom: 0; */
    }

    .rank-card-div{
        margin-top: 8px;
        border-radius: 10px;
        height: 150px;
        width: 96%;
        background-color: #FFFFFF;
    }
</style>
<script>
// import "@/assets/css/page.css"
import {getIndexProductThirdPage , getIProductLink } from '@/js/util/mgRequest.js'
import { PullRefresh } from 'vant';
import { List } from 'vant';
import { Cell } from 'vant';

export default({
    
    data(){
        return{
            list:[],
            loading:false,
            productList:[],
            bg: require('@/assets/image/temai.png'),
            listLoading:false,
            finished:false,
            currentPage:1,
            pageSize:20
        }
    },
    components: {
        [PullRefresh.name]: PullRefresh,
        [List.name]: List,
        [Cell.name]: Cell
    },
    mounted(){
    //   var _this=this;
    //   getDiscoverProduct().then((res)=>{
    //       _this.productList=res
    //   })
    },
    methods:{
        onRefresh(){
            var _this=this;
            setTimeout(() => {
                //Toast('刷新成功');
            console.log("加载")
            var _this=this;
            var pageNum=_this.currentPage;
            getIndexProductThirdPage('','',3,pageNum,20).then((res)=>{
                _this.productList=[];
                if(_this.currentPage>=res.pages){
                    _this.finished=true;
                }
                for(var index=0;index<res.data.length;index++){
                    _this.productList.push(res.data[index])
                }
                _this.currentPage++;
                _this.listLoading = false;
                _this.loading = false;
            })

            }, 1000);
        },
        jumpToThird(channel,url){
        if(channel=="TMALL"){
            window.android.jumpToTmall(url)
          }else if(channel=="TAOBAO"){
            window.android.jumpToTaobao(url)
          }else if(channel=="JD"){
             window.android.jumpToJD(url)
          }else if(channel=="PDD"){
            window.android.jumpToPDD(url)
          }else if(channel=="MT"){
            window.android.jumpToMT(url)
          }
        },
        jumpDouyin(url){
            getIProductLink(url).then((res)=>{
                console.log(res)
                window.android.jumpToDy(res.data.dy_deeplink)
            })
        },
        jumpToDetail(productId){
            //this.$router.push('/shopDetail?productId='+productId)
            window.android.jumpToCommonActivity("https://app.pxxpxxpxx.com/#/shopDetail?productId="+productId);
        },
        onLoad(){
            console.log("加载")
            var _this=this;
            var pageNum=_this.currentPage;
            getIndexProductThirdPage('','',3,pageNum,20).then((res)=>{
                if(_this.currentPage>=res.pages){
                    _this.finished=true;
                }
                for(var index=0;index<res.data.length;index++){
                    _this.productList.push(res.data[index])
                }
                _this.currentPage++;
                _this.listLoading = false;
            })
        }
    }
})
</script>

