<template>
    <div>
        <div class="flex-box-center" style="height:8VW"> 
            
        </div>
        <div class="flex-box-center"> 
        <!-- <img style="width:100%;height:100%" src="@/assets/image/discover-bg.jpg" /> -->
            <img style="height:300px;width:100%" src="@/assets/image/login-bg.png" />
        </div>
        <div class="flex-box-center"> 
            <div class="flex-box-center" style="flex-direction: column;width: 100%;"> 
                <div class="flex-box-center-col" style="width:80%"> 
                    <svg t="1654162913006" class="icon" style="height:25px;width:25px" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4490" width="48" height="48"><path d="M700.251235 87.441073H310.741002c-54.921665 0-99.726182 44.804517-99.726182 99.726182v654.724065c0 54.921665 44.804517 99.726182 99.726182 99.726182h389.510233c54.921665 0 99.726182-44.804517 99.726182-99.726182V186.444601c0-54.921665-44.804517-99.003529-99.726182-99.003528z m-389.510233 50.585744h389.510233c26.738179 0 49.140438 21.679605 49.140438 49.140438v504.412138H261.600565V186.444601c0-26.738179 22.402258-48.417784 49.140437-48.417784z m389.510233 753.00494H310.741002c-26.738179 0-49.140438-21.679605-49.140437-49.140437v-99.726182h487.791108v99.726182c0 26.738179-21.679605 49.140438-49.140438 49.140437z" fill="#707070" p-id="4491"></path><path d="M540.544813 788.414961h-70.097389c-13.730416 0-25.292872 11.562456-25.292872 25.292872s11.562456 25.292872 25.292872 25.292873h70.097389c13.730416 0 25.292872-11.562456 25.292872-25.292873s-10.839802-25.292872-25.292872-25.292872z" fill="#707070" p-id="4492"></path><path d="M409.744531 221.131969m-27.460833 0a27.460833 27.460833 0 1 0 54.921665 0 27.460833 27.460833 0 1 0-54.921665 0Z" fill="#707070" p-id="4493"></path><path d="M505.857445 221.131969m-27.460832 0a27.460833 27.460833 0 1 0 54.921665 0 27.460833 27.460833 0 1 0-54.921665 0Z" fill="#707070" p-id="4494"></path><path d="M601.97036 221.131969m-27.460833 0a27.460833 27.460833 0 1 0 54.921666 0 27.460833 27.460833 0 1 0-54.921666 0Z" fill="#707070" p-id="4495"></path></svg>
                    <input v-model="phoneNum" oninput="if(value.length>11)value=value.slice(0,11)" type="number" class="login-input" placeholder="请输入手机号码"/>
                </div>

                <!-- <hr style="width:80%"/> -->
                <div class="van-hairline--top" style="width: 80%;"></div>
                <div  class="flex-box-center-col" style="width:80%"> 
                    <svg t="1654163192199" style="height:25px;width:25px" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5011" width="48" height="48"><path d="M642.438956 772.516584H182.831334c-8.671842 0-15.898377-7.226535-15.898377-15.898377V318.690191l287.61609 287.61609c12.285109 12.285109 28.90614 19.511644 46.972477 19.511644s34.687368-7.226535 46.972477-19.511644l294.119972-294.119972v256.54199c0 13.730416 11.562456 25.292872 25.292872 25.292872s25.292872-11.562456 25.292873-25.292872V297.733239c0-36.855328-29.628793-66.484121-66.484122-66.484121H182.831334c-36.855328 0-66.484121 29.628793-66.484122 66.484121v458.884968c0 36.855328 29.628793 66.484121 66.484122 66.484122h459.607622c13.730416 0 25.292872-11.562456 25.292872-25.292872s-11.562456-25.292872-25.292872-25.292873z m158.261115-489.959068L512.361327 570.173606c-5.781228 5.781228-16.62103 5.781228-22.402259 0L202.342978 282.557516h598.357093z" fill="#8a8a8a" p-id="5012"></path><path d="M750.83698 799.254764m-28.183487 0a28.183486 28.183486 0 1 0 56.366973 0 28.183486 28.183486 0 1 0-56.366973 0Z" fill="#8a8a8a" p-id="5013"></path><path d="M862.125618 798.53211m-28.183487 0a28.183486 28.183486 0 1 0 56.366973 0 28.183486 28.183486 0 1 0-56.366973 0Z" fill="#8a8a8a" p-id="5014"></path><path d="M865.738885 685.798165m-28.183486 0a28.183486 28.183486 0 1 0 56.366972 0 28.183486 28.183486 0 1 0-56.366972 0Z" fill="#8a8a8a" p-id="5015"></path></svg>
                    <input v-model="yzm" type="number" oninput="if(value.length>4)value=value.slice(0,4)" maxlength="4" class="login-input" placeholder="请输入验证码"/>
                    <span style="min-width:80px" @click="getYzm()"  :class="[yzmCodeDisabled?'yzm-span-disabled':'yzm-span']" v-text="yzmCode"></span>
                </div >
                
                 <div class="van-hairline--top" style="width: 80%;"></div>
                <button class="login-button" @click="doLogin()">验证码登录</button>
            </div>
        </div>
        <div class="flex-box-center" style="width:100%;margin-top:20px"> 
            <van-checkbox checked-color="#FF2717" shape="square" v-model="checked"></van-checkbox>
            <span class="yhxy">登录即同意</span><span class="ysxy" @click="toYinsi()">《隐私政策》</span><span class="yhxy">和</span><span class="ysxy" @click="toXieyi()">《用户服务协议》</span>
        </div>
    </div>
</template>

<style scoped>
    .login-input{
        margin-top:5px;
        border:0;
        outline:none;
        height: 40px;
        width: 60%;
        margin-left:10px
    }

    .login-button{
        margin-top: 20px;
        border-radius: 35px;
        width: 85%;
        height: 45px;
        color: #FFFFFF;
        border:0;
        background:linear-gradient(to right,#FF1176,#FF2717)
    }
    .yzm-span{
        color:#FF2717;
        font-size:14px
    }
    .yzm-span-disabled{
        color:#C9C9C9;
        font-size:14px
    }

    .yhxy{
        font-size:12px;
        margin-left: 5px;
    }

    .ysxy{
        font-size:12px;
        color:#FF2717
    }
</style>
<script>
import { Checkbox } from 'vant';
import { ref } from 'vue';
import {addLogin,getCode} from '@/js/util/mgRequest.js'
import { Toast } from 'vant';

export default({
    setup() {
        const checked = ref(false);
        // const data= addLogin('/')
        return { checked };
    },
    data(){
        return{
            phoneNum:'',
            yzm:'',
            yzmCode:'获取验证码',
            yzmCodeDisabled:false,
            key:''
        }
    },
    components: {
        [Checkbox.name]: Checkbox,
        [Toast.name]: Toast
    },
    methods:{
        getYzm(){
            //验证是否同意协议
            if(!this.checked){
                Toast('请先同意隐私政策和用户协议');
                return;
            }
            
            //拦截多次点击
            if(this.yzmCodeDisabled==true){
                console.log('禁用点击')
                return;
            }

            //验证手机号
            const regex = /^1[3456789]\d{9}$/;
            if(!regex.test(this.phoneNum)){
                Toast('请输入正确的手机号');
                return;
            }

            var data={phone:this.phoneNum}
            let maxNum = 30;
            let oldCodeText = this.yzmCode;
            let codeCountDown = setInterval(() => {
            this.disabled = true;
            let countDownNum = maxNum--;
            this.yzmCode = `${countDownNum}s重新获取`;
            this.yzmCodeDisabled = true;
            if (countDownNum == 0) {
                //倒计时结束
                this.yzmCode = oldCodeText;
                clearInterval(codeCountDown);
                this.yzmCodeDisabled=false;
                }
            }, 1000);

            var _this=this;
            getCode(data).then((res)=>{
                _this.key=res.data.key
            })

        },
        doLogin(){
            if(this.phoneNum!=""&&this.yzm!=""){
                var data={
                    phone:this.phoneNum,
                    key: this.key,
                    code:this.yzm
                }

                addLogin(data).then((res)=>{
                    localStorage.setItem("token", res.data.token);
                    localStorage.setItem("userInfo", JSON.stringify(res.data));
                    // window.android.setUserInfo(JSON.stringify(res.data))
                    window.android.refreshToken(res.data.token)
                    window.android.finishThisPage();
                })
            }
        },
        toYinsi(){
            //window.android.jumpToCommonActivity("https://app.pxxpxxpxx.com/#/yinsi");
            this.$router.push('/yinsi')
        },
        toXieyi(){
            //window.android.jumpToCommonActivity("https://app.pxxpxxpxx.com/#/xieyi");
            this.$router.push('/xieyi')
        }
    }
})
</script>
