<template>
    <div class="my-cancel">
        <div>
            <van-nav-bar title="账号注销" left-text="" left-arrow @click-left="onClickLeft()" />
        </div>
        <div class="flex-box-center" style="margin-top:10px;"> 
            <div class="flex-box" style="width:90%;flex-direction: column;"> 
                <span class="title">注销账号后，你将无法再使用该账号，包括但不限于：</span>
                <span class="content">1.无法登录、使用省钱365APP账号</span>
                <span class="content">2.清空账号下所有的购物订单无法被找回；</span>
                <span class="content">3.清空该账号下未提现的一手APP余额且无法被找回；</span>
                <span class="content">4.清空获得的积分、红包、翻倍卡等权益物；</span>
                <span class="content">5.清空该账号下的所有个人资料且无法被找回；</span>
                <span class="content">6.移除该账号下的所有登录方式等。</span>
            </div>

        </div>
        <div class="flex-box-center" style="margin-top:20px"> 
            <van-button @click="doCancelAccount()" :loading="bt_loading" :disabled="bt_disable" :loading-text="bt_loading_text" round  block style="width:90%" color="linear-gradient(to right, #ff6034, #ee0a24)">
                我已知晓，确认注销
            </van-button>
        </div>
    </div>
</template>
<style scoped>
    .my-cancel{

    }
    .title{
        margin-top: 15px;
        font-size: 16px;
        color:red;
        margin-bottom: 15px;
    }

    .content{
        margin-top: 15px;
        font-size: 14px;
        color:#282E35
    }
</style>
<script>
import { NavBar } from 'vant';
import { Button } from 'vant';
import { Cell, CellGroup } from 'vant';
import {cancelAccount} from '@/js/util/mgRequest.js'
import { Toast } from 'vant';
import { Dialog } from 'vant';
export default({
    data() {
        return {
            bt_loading:true,
            bt_disable:true,
            bt_loading_text:''
        };
  },
    setup() {
        
    },
    components: {
        [Cell.name]: Cell,
        [CellGroup.name]: CellGroup,
        [Button.name]: Button,
        [NavBar.name]: NavBar,
        [Dialog.name]: Dialog,
    },
    mounted(){
            var _this=this;
            let second = 8;
            _this.bt_loading_text='仔细阅读协议，剩余'+second+'秒'

            const timer = setInterval(() => {
                second--;
                if (second) {
                    _this.bt_loading_text='仔细阅读协议，剩余'+second+'秒'
                } else {
                    _this.bt_loading=false;
                    _this.bt_disable=false
                    clearInterval(timer);
                }
            }, 1000);
    },
    methods:{
        doCancelAccount(){
            var _this=this;
            Dialog.confirm({
                title:'账号注销',
                message:
                    '真的要离开我们了吗？',
                })
                .then(() => {
                    cancelAccount().then((res)=>{
                        Toast.success('注销成功');
                    })
                })
                .catch(() => {
                    // on cancel
                });

        },
        onClickLeft(){
            this.$router.go(-1);
        }
    }
    
})
</script>
