import request from './request.js'

//获取验证码接口
export const getCode = (data) =>request({ url: '/mogeapi/login/getCode', method: "post", data })
//登录验证接口
export const addLogin = (data) => request({ url: '/mogeapi/login/doLogin', method: "post", data })
//个人信息接口
export const getUserInfo = () => request({ url: '/mogeapi/login/getUserInfo', method: "get" })
//获取banner图
export const getBanners = () => request({ url: '/mogeapi/promate/banner', method: "get" })
//获取金刚区广告
export const getIndexAd = () => request({ url: '/mogeapi/promate/ad', method: "get" })
//获取金刚区
export const getJingang = () => request({ url: '/mogeapi/promate/jingang', method: "get" })
//获取商品列表
export const getIndexProductPage = (typeId,pageNum,pageSize) => request({ url: '/mogeapi/promate/indexProductPage?typeId='+typeId+'&pageNum='+pageNum+'&pageSize='+pageSize, method: "get" })
//获取分类页全部商品列表
export const getTypeProductPage = (typeId,name,pageNum,pageSize) => request({ url: '/mogeapi/promate/typeProductPage?typeId='+typeId+'&name='+name+'&pageNum='+pageNum+'&pageSize='+pageSize, method: "get" })
//获取发现页商品列表
export const getDiscoverProduct = (pageNum,pageSize) => request({ url: '/mogeapi/promate/discoverProductPage?pageNum='+pageNum+'&pageSize='+pageSize, method: "get" })
//注销账号
export const cancelAccount = (data) => request({ url: '/mogeapi/login/cancelAccount', method: "get" })
//提交意见反馈
export const sendFeedback = (data) => request({ url: '/mogeapi/login/feedback?content='+data, method: "get" })
//公共话费充值接口
export const rechargeApi = (data) =>request({ url: '/mogeapi/commonPay/recharge', method: "post", data })
//公共话费充值接口
export const getMobileInfo = (phoneNum) =>request({ url: '/mogeapi/commonPay/getMobileInfo?phoneNum='+phoneNum, method: "get" })
//首页商品列表（csj）
export const getIndexProductThirdPage = (typeId,keyWords,searchType,pageNum,pageSize) => request({ url: '/mogeapi/promate/indexProductThirdPage?typeId='+typeId+'&keyWords='+keyWords+'&searchType='+searchType+'&pageNum='+pageNum+'&pageSize='+pageSize, method: "get" })
//获取商品详情
export const getIndexProductDetail = (productId) => request({ url: '/mogeapi/promate/getProductDetail?productId='+productId, method: "get" })
//获取商品转链
export const getIProductLink = (productUrl,productId) => request({ url: '/mogeapi/promate/getProductLink?productUrl='+productUrl+'&productId='+productId, method: "get" })
//获取个人的邀请码
export const getMyInviteCode = () => request({ url: '/mogeapi/invite/myInviteCode', method: "get" })
//获取邀请记录
export const getMyInviteList = (pageNum,pageSize) => request({ url: '/mogeapi/invite/myInvite?pageNum='+pageNum+'&pageSize='+pageSize, method: "get" })
//获取购物车记录
export const getShopCarList = (pageNum,pageSize) => request({ url: '/mogeapi/shopcar/list?pageNum='+pageNum+'&pageSize='+pageSize, method: "get" })
//获取金币和返利余额接口
export const getMyAccount = () => request({ url: '/mogeapi/account/myAccount', method: "get" })
//金币兑换接口
export const withdrawCoin = (coin) => request({ url: '/mogeapi/withdraw/coin?coin='+coin, method: "get" })
//返利提现接口
export const withdrawRebate = (coin) => request({ url: '/mogeapi/withdraw/rebate?rebate='+coin, method: "get" })
//绑定微信
export const bindWxReq = (data) => request({ url: '/mogeapi/login/bindWX', method: "post",data})
//自动登录
export const autoLogin = () => request({ url: '/mogeapi/login/autoLogin', method: "post"})
//搜索商品
export const searchProduct = (keyWords,pageNum,pageSize) => request({ url: '/mogeapi/promate/searchProduct?keyWords='+keyWords+'&pageNum='+pageNum+'&pageSize='+pageSize, method: "get"})
//订单接口
export const getOrders = (status,pageNum,pageSize) => request({ url: '/mogeapi/order/list?status='+status+'&pageNum='+pageNum+'&pageSize='+pageSize, method: "get"})