<template>
<div>
        <van-nav-bar title="金币兑换" left-text="" left-arrow @click-left="onClickLeft()" />
    </div>
    <div class="flex-box-center-row flex-row header">
        <div class="flex-box-center-col flex-col" style="flex:1;margin-top:60px;"> 
                <span class="s-font">金币收益</span>
                <span class="s-font" style="color:#C9C9C9;margin-top: 5px;">金币每天0点自动兑换现金</span>
                <span class="big-font">{{myAccount.coinBanalce}}</span>

            </div>
            <div class="flex-box-center-col flex-col" style="flex:1;margin-top: 60px;"> 
                <span class="s-font">现金收益(元)</span>
                <span class="s-font" style="color:#C9C9C9;margin-top: 5px;">兑换现金比例浮动</span>
                <span class="big-font">{{(myAccount.coinBanalce/rate).toFixed(2)}}</span>

            </div>
    </div>
    <div class="flex-box-center" style="margin-top:-100px">
        <div class="tixian"> 
            <div style="margin-top:80px"> 
                <div class="coin-item">
                    <div class="flex-box" style="margin-left:12px"><span>活动提现</span></div>
                        <div class="flex-box" style="flex-wrap:wrap">
                            <div class="flex-box-center jine-div"  :class="{'jine-div-checked':jineActivity==0.3}" @click="chooseAmount(0.3)">
                                <span>0.3元</span>
                            </div>
                        </div>
                        <div style="margin-left:12px;margin-top: 10px;"> 
                            <span>普通提现</span>
                        </div>
                <div class="flex-box" style="flex-wrap:wrap"> 
                    <div class="flex-box-center jine-div"  :class="{'jine-div-checked':jineActivity==5}" @click="chooseAmount(5)">
                        <span>5元</span>
                    </div>
                    <div class="flex-box-center jine-div" :class="{'jine-div-checked':jineActivity==10}" @click="chooseAmount(10)">
                        <span>10元</span>
                    </div>
                    <div class="flex-box-center jine-div" :class="{'jine-div-checked':jineActivity==30}" @click="chooseAmount(30)">
                        <span>30元</span>
                    </div>
                    <div class="flex-box-center jine-div" :class="{'jine-div-checked':jineActivity==50}" @click="chooseAmount(50)">
                        <span>50元</span>
                    </div>
                    <div class="flex-box-center jine-div" :class="{'jine-div-checked':jineActivity==80}" @click="chooseAmount(80)">
                        <span>80元</span>
                    </div>
                    <div class="flex-box-center jine-div" :class="{'jine-div-checked':jineActivity==100}" @click="chooseAmount(100)">
                        <span>100元</span>
                    </div>
                </div>
                <div class="flex-box-center" style="margin-top:20px">   
                    <div @click="coinBack()"><img style="width:100%;height:40px" src="@/assets/image/lijitixian.png" /> </div>
                    <!-- <button class="btn-coin" @click="coinBack()">立即提现</button> -->
                </div>
            </div>
            </div>
        </div>
    </div>
    <div>
        <van-list v-model:loading="listLoading" :finished="finished" finished-text="" @load="onLoad" style="padding-bottom: 25vw;" >
          <div class="flex-box" style="flex-wrap: wrap;margin-top: 1vw;" > 
            <div v-for="item in list" :key="item.newCustPhone" class="flex-box-center" style="width: 100%;margin-top: 12px;">
                <div class="flex-box" style="width:100%;margin-left: 20px;margin-right: 20px;"> 
                    <div class="flex-box flex-col" style="width:100%">
                        <span style="font-size:14px">邀请奖励</span>
                        <span style="font-size:14px;color:#8B8A8F;margin-top: 5px;">{{item.createTime}}</span>
                    </div>
                    <div class="flex-box-center-col" style="width:100%;">
                        <span style="margin-left: auto;font-size: 14px;color:#FC2B55;font-weight: 600;">+{{item.totalCoin}}金币</span>
                    </div>
                </div>
            </div>
    </div>
    </van-list>
    </div>
</template>
<style scoped>
    .header{
        height: 280px;
        background :url('@/assets/image/banyuan.png') no-repeat;
        background-size: 100%;
        padding: 25px;
    }

    .tixian{
        border-radius: 15px;
        width: 90%;
        height: 420px;
        background: blue;
        background: url("@/assets/image/lingxianjin.png") no-repeat;
        background-size: 100%;
        box-shadow: rgb(104 102 102 / 30%) 0 0 10px;
    }

    .big-font{
        margin-top: 8px;
        font-size:35px;
        font-weight: 600;
        color:#FFD266;
    }

    .s-font{
        font-size:12px;
        color:#FFF4FB;
    }

    .jine-div{
        background: url('@/assets/image/jine-default.png');
        background-size: 100%;
        height: 60px;
        width: 100px;
        margin-left: 12px;
        margin-top: 10px;
        border:none
     }

     .jine-div-checked{
        background: url('@/assets/image/jine-checked.png');
        background-size: 100%;
     }
     
     .btn-coin{
        background: #3AAF2B;
        border: none;
        border-radius: 30px;
        height:45px;
        width:80%;
        color:#FFFFFF
     }
</style>
<script>
import { List } from 'vant';
import { getMyAccount,withdrawCoin ,getMyInviteList} from '@/js/util/mgRequest.js'
import { Toast } from 'vant';
import { NavBar } from 'vant';
export default({
    data() {
        return {
            loading:false,
            list:[],
            listLoading:false,
            finished:false,
            currentPage:1,
            pageSize:20,
            myAccount:{},
            jineActivity:-1,
            rate:10000,
        };
    },
    components: {
        [List.name]: List,
        [Toast.name]: Toast,
        [NavBar.name]: NavBar
    },
    methods:{
        onLoad(){
            let _this=this;
            let pageNum=_this.currentPage;
            getMyInviteList(pageNum,20).then((res)=>{
              if(_this.currentPage>=res.pages){
                    _this.finished=true;
                }
                for(var index=0;index<res.data.length;index++){
                    _this.list.push(res.data[index])
                }
                _this.currentPage++;
                _this.listLoading = false;
            })
        },
        chooseAmount(amount){
            this.jineActivity=amount
        },
        coinBack(){
            if(this.jineActivity<0){
                Toast('请选择要兑换的金额')
                return;
            }
            var coin= this.jineActivity*this.rate;
            withdrawCoin(coin).then((res)=>{
                if(res.code!=0){
                    Toast(res.message)
                }else{
                    Toast('兑换成功，请关注微信到账信息')
                }
            })
        },
        onClickLeft(){
            window.android.finishThisPage();
        }
    },
    mounted () {
        var _this=this;
        getMyAccount().then((res)=>{
            _this.myAccount=res.data;
            _this.rate=res.data.rate
        })
    },
})
</script>
